<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
// import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import TabComponent from "./tabComponent.vue";
// import vue2Dropzone from "vue2-dropzone";

// import "vue2-dropzone/dist/vue2Dropzone.min.css";

// import tableData from "@/components/tablecomponent"

export default {
  name: "TabServicecomponents",
  page: {
    title: "Advanced Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Multiselect,
    DatePicker,
    TabComponent,
    // vueDropzone: vue2Dropzone,
    // tableData,
  },
  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,

      inspections: [],

      rowsSelectOne0: "",
      rowsSelectOne1: "",
      rowsSelectOne2: 2,

      inspDate: "",
      distance: "",
      expectFinishTime: "",
      rowsInspid: "",
      svId: this.$route.params.id,
      svIdCre: this.$route.params.svId,
      svIdDecode: this.$route.params.id,
      svIdCreDecode: this.$route.params.svId,

      sliderWithLabel: {
        value: 1,
        data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        range: [
          {
            label: "1",
          },
          {
            label: "2",
            isHide: true,
          },
          {
            label: "3",
          },
          {
            label: "3",
            isHide: true,
          },
          {
            label: "5",
          },
          {
            label: "6",
            isHide: true,
          },
          {
            label: "7",
          },
          {
            label: "8",
            isHide: true,
          },
          {
            label: "9",
          },
          {
            label: "10",
            isHide: true,
          },
        ],
        rangeValue: {},
      },

      title: "ใบตรวจสภาพรถ",
      items: [
        {
          text: "Home",
        },
        {
          text: "ใบตรวจสภาพรถ",
          active: true,
        },
      ],
      rows: [],
      options: [
        { text: " ครบ", value: "0" },
        { text: " ไม่ครบ", value: "1" },
      ],
      optionsClean: [
        { text: " ล้าง", value: "0" },
        { text: " ไม่ล้าง", value: "1" },
      ],
      inspType: "BP",
      index: 0,
      visible: false,
      distanceKg: "",
      inspId: "",
      extraValue: "",
      extraValue2: "",
      note: "",
      btnselected: "",
      btnselected2: "",

      rowsSelect: [],
      rowsMasterSD: [],
      rowsShow: [],
      rowsShowinspection: [],
      rowsImg: [],
      allImg: "",
      resalert: "",
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      filter: null,
      filterOn: [],
      rowsTest: [],
      token: "",
      sortBy: "age",
      sortDesc: false,
      submitform: false,
      allRuleid: "",
      fields2: [
        {
          nameTh: true,
          // sortable: false,
        },
        {
          key: "practiceTh",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields3: [
        {
          key: "title",
          label: "ภายในรถ",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "nameTh",
          label: "Id",
          sortable: false,
        },
        {
          key: "practiceTh",
          label: "Id",
          sortable: false,
        },
        {
          key: "radioBtn",
          label: "",
          sortable: false,
        },
        {
          key: "radioBtn2",
          label: "",
          sortable: false,
        },
        {
          key: "radioBtn3",
          label: "",
          sortable: false,
        },
      ],
      overlayFlag: false,
      dropzoneOptions: {
        url:
          `${appConfig.api.baseURL}api/service/inspection/image/upload`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        uploadMultiple: true,
        paramName: "imgFile",
        init: function() {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
    };
  },
  validations: {
    inspDate: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.token = `${user[appConfig.storageTokenKeyName]}`;
    // this.token.push(JSON.stringify(this.user))
    // Set the initial number of items
    // this.totalRows = this.rowsTest.length;
  },
  created() {
    if (this.svIdCreDecode != null) {
      this.getData();
    }
    if (this.svIdDecode != null) {
      this.getDataDetail();
    }

    this.getDataServiceDistances();
    this.inspDate = "";
    // if (){

    // }
    // this.getDataImage();
    // this.select()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    sendingEvent(file, xhr, formData) {
      formData.append("svId", this.svId);
      formData.append("inspId", this.inspId);
      formData.append("ruleId", this.allRuleid);
    },
    retrieveOptionsDiffPage(choiceId, ruleId) {
      ruleId.choiceId = choiceId;
    },
    // getDataImageDropzone: function() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/service/inspection/images", {
    //       params: {
    //         svId: this.svId,
    //         inspId: this.inspId,
    //         ruleId: this.allRuleid,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsImg = response.data.images;

    //       const arr = [];
    //       this.rowsImg.forEach((item) => {
    //         arr.push(item.path);
    //       });
    //       this.allImg = arr;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    getData: function() {
      const decodedString = atob(this.svIdCreDecode);

      this.svIdCreDecode = decodedString;
      if (Number.isInteger(this.svIdCreDecode) === true) {
        const decodedStringsvId = atob(this.svIdDecode);
        this.svIdDecode = decodedStringsvId;
      }
      // const decodedStringsvId = atob(this.svIdDecode);
      // this.svIdDecode = decodedStringsvId;
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdCreDecode != null ? this.svIdCreDecode : this.svIdDecode,
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.inspections = response.data.data;
            this.rowsInspid = response.data.data[0].inspId;
            // this.rows.total = response.data.total;

            this.getDataServiceShowDetail();
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    loadData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdCreDecode != null ? this.svIdCreDecode : this.svIdDecode,
          },
        })
        .then((response) => {
          this.inspections = response.data.data;
          this.rowsInspid = response.data.data[0].inspId;
          // this.rows.total = response.data.total;

          this.getDataServiceShowDetail();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdCreDecode != null ? this.svIdCreDecode : this.svIdDecode,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rowsInspid = response.data.data[0].inspId;
          // this.rows.total = response.data.total;

          this.getDataServiceShowDetail();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // getDataServiceDetail: function() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/service/inspection/show", {
    //       params: {
    //         svId:
    //           this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
    //         inspId: this.inspId,
    //         // svId: this.svId,
    //         // inspId: this.inspId,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsShow = response.data.inspectionDetailData;
    //       this.rowsShowinspection = response.data.inspection;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    loadDataServiceDetail: function() {
      if (this.inspId != null) {
        this.overlayFlag = true;
        useNetw
          .get("api/service/inspection/show", {
            params: {
              svId:
                this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
              inspId: this.inspId,
              // svId: this.svId,
              // inspId: this.inspId,
            },
          })
          .then((response) => {
            this.rowsShow = response.data.inspectionDetailData;
            this.rowsShowinspection = response.data.inspection;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    // getDataServiceDetailView: function(inspId) {
    //   this.inspId = inspId;
    //   this.overlayFlag = true;
    //   useNetw
    //     .get("api/service/inspection/show", {
    //       params: {
    //         svId:
    //           this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
    //         inspId: this.inspId,
    //         // svId: this.svId,
    //         // inspId: this.inspId,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsShow = response.data.inspectionDetailData;
    //       this.rowsShowinspection = response.data.inspection;
    //       this.extraValue =
    //         response.data.inspectionDetailData[1].rule[0].extraValue;
    //       this.extraValue2 =
    //         response.data.inspectionDetailData[2].rule[3].extraValue;
    //       // this.selectChoice10 = response.data.inspectionDetailData[0].rule[0].selectChoice;
    //       // this.selectChoice11 = response.data.inspectionDetailData[0].rule[1].selectChoice;
    //       // this.selectChoice12 = response.data.inspectionDetailData[0].rule[2].selectChoice;

    //       // this.asd = response.data.inspection[1].rule[0].extraValue;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //       // this.getDataImageDropzone()
    //     });
    // },

    getDataServiceShowDetail: function() {
      if (this.rowsInspid != null) {
        this.overlayFlag = true;
        useNetw
          .get("api/service/inspection/show", {
            params: {
              svId:
                this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
              inspId: this.rowsInspid,
              // svId: this.svId,
              // inspId: this.inspId,
            },
          })
          .then((response) => {
            this.rowsShow = response.data.inspectionDetailData;
            this.rowsShowinspection = response.data.inspection;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },

    getDataServiceDistances: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/master/service-distances", {})
        .then((response) => {
          this.rowsMasterSD = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // getDataImage: function(svId, inspId, allRuleid) {
    //   this.overlayFlag = true;
    //   this.svId = svId;
    //   this.inspId = inspId;
    //   this.allRuleid = allRuleid;
    //   useNetw
    //     .get("api/service/inspection/images", {
    //       params: {
    //         svId: this.svId,
    //         inspId: this.inspId,
    //         ruleId: this.allRuleid,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsImg = response.data.images;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submitServiceInspection: function() {
      const bodyFormData = new FormData();
      bodyFormData.append(
        "svId",
        this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode
      );
      bodyFormData.append("inspDate", this.inspDate);
      bodyFormData.append("inspType", this.inspType);
      useNetw
        .post("/api/service/inspection/store", bodyFormData)
        .then((response) => {
          this.inspId = response.data.inspId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateService.hide();
          this.loadData();
          this.loadDataServiceDetail();
        });
    },
    uploadImgServiceInspection: function() {
      // this.svId.push({svId})
      const bodyFormData = new FormData();
      bodyFormData.append("svId", 4);
      bodyFormData.append("inspId", 1);
      bodyFormData.append("ruleId", 1);
      // const imagefile = document.querySelector('#imgFile')
      // bodyFormData.append("imgFile", imagefile.file);
      useNetw
        .post("/api/service/inspection/image/upload", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateService.hide();
          this.loadData();
          this.loadDataServiceDetail();
        });
    },
    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
    postAPI(svId, inspId, ruleId, value) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: JSON.stringify(value),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postExtraValue(svId, inspId, ruleId, extraValue2) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: extraValue2,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postExtraFuel(svId, inspId, ruleId, fuel) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: fuel,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postNote(svId, inspId, ruleId, note) {
      useNetw
        .put("/api/service/inspection/update-choice-note", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          note: note,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postSelectChoice(svId, inspId, ruleId, selectChoice) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: selectChoice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    chooseFiles(svId, inspId, ruleId) {
      document.getElementById("importFile").click();
      this.allRuleid = ruleId;
    },
    postImg(svId, inspId) {
      this.svId = svId;
      const bodyFormData = new FormData();
      bodyFormData.append("svId", this.svId);
      bodyFormData.append("inspId", inspId);
      bodyFormData.append("ruleId", this.allRuleid);
      const imagefile = document.querySelector("#importFile");
      bodyFormData.append("imgFile[]", imagefile.files[0]);
      useNetw
        .post("/api/service/inspection/image/upload", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateService.hide();
          this.loadData();
          this.loadDataServiceDetail();
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitServiceInspection();
      }
    },
  },
  middleware: "authentication",
};
</script>

<style>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

<template>
  <b-tab>
    <template v-slot:title>
      <i class="uil uil-user-circle font-size-20"></i>
      <span class="d-none d-sm-block">ใบตรวจสภาพรถ</span>
    </template>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 text-end mb-2">
        <b-button variant="primary" v-b-modal.modalCreateService class="m-2"
          ><i class="uil uil-plus"></i>สร้าง</b-button
        >
      </div>
    </div>
    <div>
      <b-alert
        class="alert-fixed fixed-top rounded-1 m-4 bg-light"
        :show="dismissCountDown"
        dismissible
        fade
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p class="float-end">{{ dismissCountDown }} วินาที...</p>
        <p>แจ้งเตือน</p>
        <hr />
        <p>{{ resalert }}</p>
        <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
      </b-alert>
    </div>
    <div>
      <b-tabs justified nav-class="nav-tabs-custom" class="text-muted">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <b-tab
            :title="inspection.inspCode"
            v-for="(inspection, index) in inspections"
            :key="index"
          >
            <tab-component :inspId="inspection.inspId"></tab-component>
          </b-tab>
        </b-overlay>
      </b-tabs>
    </div>

    <b-modal
      ref="modalCreateService"
      id="modalCreateService"
      title="สร้าง Service"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-6 col-sm-7 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code
              ><label for="validation">ประเภทการเข้ารับบริการ:</label>
              <div class="row">
                <div class="col-4">
                  <b-form-radio
                    v-model="inspType"
                    name="some-radios"
                    value="BP"
                  >
                    BP</b-form-radio
                  >
                </div>
                <div class="col-4">
                  <b-form-radio
                    v-model="inspType"
                    name="some-radios"
                    value="GS"
                  >
                    GS</b-form-radio
                  >
                </div>
                <div class="col-4">
                  <b-form-radio
                    v-model="inspType"
                    name="some-radios"
                    value="SLA"
                  >
                    SLA</b-form-radio
                  >
                </div>
              </div>
            </div>
            <!-- <div class="mb-3 position-relative">
              <code>* </code><label for="validation">เช็คระยะ</label>
              <multiselect
                v-model="distance"
                label="nameTh"
                :options="rowsMasterSD"
                :show-labels="false"
                open-direction="bottom"
                placeholder=""
                class="helo"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
            </div> -->
          </div>

          <div class="col-6 col-sm-5 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">วันที่เข้ารับบริการ:</label>
              <date-picker
                v-model="inspDate"
                format="YYYY-MM-DD"
                value-type="format"
                :class="{
                  'is-invalid': submitform && $v.inspDate.$error,
                }"
              ></date-picker>
              <div
                v-if="submitform && $v.inspDate.$error"
                class="invalid-tooltip"
              >
                <span v-if="!$v.inspDate.required">Please Selected Data.</span>
              </div>
            </div>
          </div>
          <!-- <div class="col-6 col-sm-6 col-lg-2">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">เวลาสิ้นสุด</label>
              <date-picker
                v-model="expectFinishTime"
                format="H:mm"
                value-type="format"
                type="time"
              >
              </date-picker>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="text-end">
            <b-button
              class="btn btn-primary"
              variant="primary"
              @click="tooltipForm()"
            >
              บันทึก
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- <b-modal
      ref="modalViewimage"
      id="modalViewimage"
      title="รูปภาพประกอบ"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-12">
            <div class="mb-3 position-relative">
              <vue-dropzone
                id="imgFile"
                ref="imgFile"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="getDataImage"
                v-on:vdropzone-sending="sendingEvent"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>อัพโหลดไฟล์ภาพ</h4>
                </div>
              </vue-dropzone>
              <b-overlay
                :show="overlayFlag"
                :spinner-variant="this.$overlayVariant"
                :spinner-type="this.$overlayType"
                :rounded="this.$overlayRounded"
              >
                <div class="card mt-4">
                  <div class="card-body shadow">
                    <h4 class="card-title">
                      อัลบั้มภาพ
                      <span class="badge bg-danger"></span>
                    </h4>
                    <hr />
                    <div class="popup-gallery">
                      <div
                        v-for="(data, index) in rowsImg"
                        :key="'imgShow' + index"
                        class="float-start m-2"
                        @click="() => showImg(index)"
                      >
                        <div class="card shadow">
                          <img
                            :src="data.path"
                            class="d-block"
                            width="180"
                            height="180"
                          />
                        </div>
                      </div>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="this.allImg"
                        :index="index"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
  </b-tab>
</template>
