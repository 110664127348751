<template>
  <b-tab active>
    <template v-slot:title>
      <i class="uil uil-clipboard-notes font-size-20"></i>
      <span class="d-none d-sm-block">ใบแจ้งซ่อม</span>
    </template>
    <div class="row">
      <div class="col-12 text-end">
        <b-button variant="primary" v-b-modal.modalCreateRo
          ><i class="uil uil-plus"></i>สร้างใบแจ้งซ่อม</b-button
        >
      </div>
    </div>
    <div>
      <div class="row mt-4"></div>
      <b-alert
        class="alert-fixed fixed-top rounded-1 m-4 bg-light"
        :show="dismissCountDown"
        dismissible
        variant="success"
        fade
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p class="float-end">{{ dismissCountDown }} วินาที...</p>
        <p>แจ้งเตือน</p>
        <hr />
        <p>{{ resalert }}</p>
      </b-alert>
      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="p-3 text-muted"
      >
        <b-tab
          :title="rowRo.roCode"
          v-for="(rowRo, index) in rowRo"
          :key="index"
          @click="getRoShow(rowRo.roId)"
        >
        </b-tab>
        <!-- นำรถเข้าโรงซ่อม -->

        <!-- ค่าแรง -->
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="row" v-show="isHidden">
            <div class="row mb-4">
              <div class="col text-end ms-1">
                <b-button variant="danger" @click="getRoPrint()"
                  ><i class="uil-print"></i> </b-button
                >&nbsp;
                <b-button
                  v-if="this.statusSv == 'DOC'"
                  variant="primary"
                  v-b-modal.modalCreateRepair
                  ><i class="uil uil-plus"></i> Workshop</b-button
                >
                <p
                  class="badge bg-success font-size-14 float-end"
                  v-if="this.statusSv == 'FIN'"
                >
                  ส่งมอบแล้ว
                </p>
                <p
                  class="badge bg-warning font-size-14 float-end"
                  v-if="this.statusSv == 'WIP' || this.statusSv == 'DLY'"
                >
                  กำลังดำเนินการ
                </p>
              </div>
            </div>
          </div>
          <br />
          <div class="card-body" v-show="isHidden">
            <form class="needs-validation" @submit.prevent="formDisUp">
              <div class="row">
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="mileNoUp">เลขกิโลเมตร:</label>
                    <input
                      id="mileNoUp"
                      v-model="formdisUp.mileNoUp"
                      type="text"
                      class="form-control"
                      value="Otto"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="roTypeUp">ประเภท:</label>
                    <multiselect
                      id="roTypeUp"
                      v-model="formdisUp.roTypeUp"
                      :options="optionRoType"
                      :class="{
                        'is-invalid':
                          submitformUp && $v.formdisUp.roTypeUp.$error,
                      }"
                      open-direction="bottom"
                      track-by="id"
                      label="id"
                      :show-labels="false"
                    >
                    </multiselect>
                    <div
                      v-if="submitformUp && $v.formdisUp.roTypeUp.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formdisUp.roTypeUp.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="roDateUp">วันที่แจ้งซ่อม:</label>
                    <!-- disabled="disabled"  -->
                    <date-picker
                      v-model="formdisUp.roDateUp"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                      :class="{
                        'is-invalid':
                          submitformUp && $v.formdisUp.roDateUp.$error,
                      }"
                    ></date-picker>
                    <div
                      v-if="submitformUp && $v.formdisUp.roDateUp.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formdisUp.roDateUp.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="mb-2">
                    <label class="form-label d-block mb-3"
                      >ลูกค้านัดหมาย:</label
                    >
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isBookingUp"
                        id="isBooking"
                        name="outer-group[0][isBooking]"
                        class="mb-3"
                        plain
                        value="1"
                        unchecked-value="0"
                        >ใช่</b-form-radio
                      >
                    </div>
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isBookingUp"
                        id="isBooking2"
                        name="outer-group[0][isBooking]"
                        class="mb-3"
                        value="0"
                        plain
                        >ไม่ใช่</b-form-radio
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="mb-2">
                    <label class="form-label d-block mb-3">ล้างรถ:</label>
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isWashUp"
                        id="isWash"
                        name="outer-group[0][isWash]"
                        class="mb-3"
                        value="1"
                        unchecked-value="0"
                        plain
                        >ล้าง</b-form-radio
                      >
                    </div>
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isWashUp"
                        id="isWash2"
                        name="outer-group[0][isWash]"
                        class="mb-3"
                        value="0"
                        unchecked-value="0"
                        plain
                        >ไม่ล้าง</b-form-radio
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="mb-2">
                    <label class="form-label d-block mb-3">สถานะ:</label>
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isWaitingUp"
                        id="isWaiting"
                        name="outer-group[0][isWaiting]"
                        class="mb-3"
                        value="0"
                        plain
                        >รอ</b-form-radio
                      >
                    </div>
                    <div class="custom-radio form-check form-check-inline">
                      <b-form-radio
                        v-model="formdisUp.isWaitingUp"
                        id="isWaiting2"
                        name="outer-group[0][isWaiting]"
                        class="mb-3"
                        value="1"
                        plain
                        >ไม่รอ</b-form-radio
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="mb-3 position-relative">
                    <label for="note">หมายเหตุ:</label>
                    <!-- disabled="disabled"  -->
                    <textarea
                      v-model="formdisUp.noteUp"
                      class="form-control"
                      name="textarea"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    <button class="btn btn-success float-end" type="submit">
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- 5 tab -->
          <b-tabs
            content-class="p-4"
            justified
            class="nav-tabs-custom"
            v-if="isHidden"
          >
            <!-- ค่าแรง -->
            <code
              >** หมายเหตุ : กรณีแก้ไขข้อมูล เช่น ราคา,จำนวน,ส่วนลด
              หลังแก้ไขเสร็จให้กดปุ่ม Enter เพื่อบันทึกข้อมูล</code
            >
            <b-tab active>
              <template v-slot:title>
                <span class="d-none d-sm-block">ค่าแรง</span>
              </template>
              <div class="p-4 border-top">
                <form class="needs-validation" @submit.prevent="wageArray">
                  <div class="row p-4">
                    <div class="col-md-6 col-sm-6">
                      <strong><code>* </code>ประเภทส่วนลด</strong>

                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="wage.bath"
                          id="isWash"
                          name="outer-group[0][isWash]"
                          :options="optionTypeDc"
                          class="mb-3"
                          value-field="item"
                          text-field="name"
                          @input="getRoWage()"
                          plain
                          >บาท</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="wage.per"
                          id="isWash2"
                          :options="optionTypeDc"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value-field="item"
                          text-field="name"
                          @input="getRoWage()"
                          plain
                          >เปอร์เซ็น</b-form-radio
                        >
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      >รายการค่าแรง
                      <span class="badge bg-info font-size-12 ms-2"
                        >{{ this.rowRoWages.length }}
                      </span>
                      รายการ</span
                    >
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>ค่าแรง</th>
                          <th style="text-align: center">ราคา/หน่วย</th>
                          <th>ชั่วโมงงาน</th>
                          <th style="text-align: center">มูลค่า</th>
                          <th style="text-align: center">ส่วนลด</th>
                          <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                          <th style="text-align: center">สุทธิ</th>
                          <th style="text-align: center">แก้ไข|ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(wageItem, index) in rowRoWages"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td scope="row">
                            <multiselect
                              class="wage"
                              type="search"
                              :options="wageOptions"
                              v-model="wageItem.selectWage"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getWage($event)"
                              @input="upsertWage(wageItem.selectWage, wageItem)"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                              :class="{
                                'is-invalid': form && $v.wageItem.wageId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>

                              <div
                                v-if="form && $v.wageItem.wageId.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.wageItem.wageId.required">{{
                                  message
                                }}</span>
                              </div>
                            </multiselect>
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="wageItem.pricePerOne"
                              class="form-control text-end number"
                              @input="totalWage(wageItem)"
                              @change="
                                updateRoWage(
                                  wageItem.wageId,
                                  wageItem.pricePerOne,
                                  wageItem.amount,
                                  wageItem.totalPrice,
                                  wageItem.dcPer,
                                  wageItem.dcPrice,
                                  wageItem.netPrice
                                )
                              "
                              @keyup.enter="
                                updateRoWage(
                                  wageItem.wageId,
                                  wageItem.pricePerOne,
                                  wageItem.amount,
                                  wageItem.totalPrice,
                                  wageItem.dcPer,
                                  wageItem.dcPrice,
                                  wageItem.netPrice
                                )
                              "
                              :class="{
                                'is-invalid':
                                  form && $v.wageItem.pricePerOne.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.wageItem.pricePerOne.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.wageItem.pricePerOne.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="">
                            <input
                              type="number"
                              @keyup.enter="
                                updateRoWage(
                                  wageItem.wageId,
                                  wageItem.pricePerOne,
                                  wageItem.amount,
                                  wageItem.totalPrice,
                                  wageItem.dcPer,
                                  wageItem.dcPrice,
                                  wageItem.netPrice
                                )
                              "
                              min="0"
                              v-model="wageItem.amount"
                              @input="totalWage(wageItem)"
                              @change="totalWage(wageItem)"
                              class="form-control text-end Dc"
                              :class="{
                                'is-invalid': form && $v.wageItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.wageItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.wageItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="wageItem.totalPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="wageItem.dcPer"
                              class="form-control text-end Dc"
                              @input="totalWage(wageItem)"
                              @change="totalWage(wageItem)"
                              @keyup.enter="
                                updateRoWage(
                                  wageItem.wageId,
                                  wageItem.pricePerOne,
                                  wageItem.amount,
                                  wageItem.totalPrice,
                                  wageItem.dcPer,
                                  wageItem.dcPrice,
                                  wageItem.netPrice
                                )
                              "
                              append=".00"
                              :class="{
                                'is-invalid': form && $v.wageItem.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.wageItem.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.wageItem.dcPer.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="wageItem.dcPrice"
                              class="form-control text-end number"
                            />
                          </td>

                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="wageItem.netPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td>
                            <ul class="list-inline mb-0 action">
                              <li class="list-inline-item">
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="alertWage(wageItem, index)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="text-end">
                            มูลค่ารวม :
                          </th>
                          <td class="text-end">
                            {{ Number(this.roWageTotalPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ this.roWageDcPer }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดยอดรวม(บาท) :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roWageDcPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            สุทธิรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roWagePrice).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div class="col-md-6 ml-4">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="addformWage()"
                        >
                          <i class="mdi mdi-plus me-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab>
            <!-- ค่าอะไหล่ -->
            <b-tab>
              <template v-slot:title>
                <span class="d-none d-sm-block">ค่าอะไหล่</span>
              </template>
              <div class="p-4 border-top">
                <form class="needs-validation" @submit.prevent="formDis">
                  <div class="row p-4">
                    <div class="col-md-6 col-sm-6">
                      <strong><code>* </code>ประเภทส่วนลด</strong>

                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="part.bath"
                          id="isWash"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="1"
                          unchecked-value="0"
                          @input="getRoPart()"
                          plain
                          >บาท</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="part.per"
                          id="isWash2"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="2"
                          unchecked-value="0"
                          @input="getRoPart()"
                          plain
                          >เปอร์เซ็น</b-form-radio
                        >
                      </div>
                    </div>
                  </div>

                  <span
                    >รายการอะไหล่
                    <span class="badge bg-info font-size-12 ms-2"
                      >{{ this.rowRoParts.length }}
                    </span>
                    รายการ</span
                  >
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>ค่าอะไหล่</th>
                          <th style="text-align: center">ราคา/หน่วย</th>
                          <th style="text-align: center">ชิ้น</th>
                          <th style="text-align: center">มูลค่า</th>
                          <th style="text-align: center">ส่วนลด</th>
                          <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                          <th style="text-align: center">สุทธิ</th>
                          <th style="text-align: center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(partItem, index) in rowRoParts"
                          :key="index"
                        >
                          <!-- @search-change="getWage" -->
                          <td>{{ index + 1 }}</td>
                          <td scope="row">
                            <multiselect
                              class="part"
                              type="search"
                              :options="rowParts"
                              v-model="partItem.selectPart"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :show-labels="false"
                              @search-change="getParts($event)"
                              @input="upsertPart(partItem.selectPart, partItem)"
                              label="nameTh"
                              placeholder=""
                              :class="{
                                'is-invalid': part && $v.partItem.partId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </td>

                          <td class="">
                            <input
                              min="0"
                              v-model="partItem.pricePerOne"
                              class="form-control text-end price"
                              @change="totalPart(partItem)"
                              @input="totalPart(partItem)"
                              @keyup.enter="
                                updateRoPart(
                                  partItem.partId,
                                  partItem.pricePerOne,
                                  partItem.amount,
                                  partItem.totalPrice,
                                  partItem.dcPer,
                                  partItem.dcPrice,
                                  partItem.netPrice
                                )
                              "
                            />
                          </td>
                          <td class="">
                            <input
                              type="number"
                              min="0"
                              v-model="partItem.amount"
                              class="form-control text-end numberP"
                              @keyup.enter="
                                updateRoPart(
                                  partItem.partId,
                                  partItem.pricePerOne,
                                  partItem.amount,
                                  partItem.totalPrice,
                                  partItem.dcPer,
                                  partItem.dcPrice,
                                  partItem.netPrice
                                )
                              "
                              @change="totalPart(partItem)"
                              @input="totalPart(partItem)"
                            />
                          </td>
                          <td class="">
                            <input
                              disabled
                              v-model="partItem.totalPrice"
                              class="form-control text-end price"
                            />
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="partItem.dcPer"
                              @change="totalPart(partItem)"
                              @input="totalPart(partItem)"
                              @keyup.enter="
                                updateRoPart(
                                  partItem.partId,
                                  partItem.pricePerOne,
                                  partItem.amount,
                                  partItem.totalPrice,
                                  partItem.dcPer,
                                  partItem.dcPrice,
                                  partItem.netPrice
                                )
                              "
                              class="form-control text-end numberP"
                              append=".00"
                            />
                          </td>
                          <td class="">
                            <input
                              disabled
                              v-model="partItem.dcPrice"
                              class="form-control text-end price"
                            />
                          </td>

                          <td>
                            <input
                              disabled
                              v-model="partItem.netPrice"
                              class="form-control text-end price"
                            />
                          </td>
                          <td>
                            <ul class="list-inline mb-0 action">
                              <li class="list-inline-item">
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="alertPart(partItem, index)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row" colspan="6" class="text-end">
                            มูลค่ารวม :
                          </th>
                          <td class="text-end">
                            {{ Number(this.roPartTotalPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ this.roPartDcPer }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดยอดรวม (บาท) :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roPartDcPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            สุทธิรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roPartNetPrice).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div class="col-md-6 ml-4">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="addformPart()"
                        >
                          <i class="mdi mdi-plus me-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab>
            <!-- ของเหลว -->
            <b-tab>
              <template v-slot:title>
                <span class="d-none d-sm-block">ของเหลว</span>
              </template>
              <div class="p-4 border-top">
                <form class="needs-validation" @submit.prevent="liquidArray">
                  <div class="row p-4">
                    <div class="col-md-6 col-sm-6">
                      <strong><code>* </code>ประเภทส่วนลด</strong>

                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="liquid.bath"
                          id="isWash"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="1"
                          unchecked-value="0"
                          @input="getRoLiquid()"
                          plain
                          >บาท</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="liquid.per"
                          id="isWash2"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="2"
                          unchecked-value="0"
                          @input="getRoLiquid()"
                          plain
                          >เปอร์เซ็น</b-form-radio
                        >
                      </div>
                    </div>
                  </div>
                  <span
                    >รายการของเหลว
                    <span class="badge bg-info font-size-12 ms-2"
                      >{{ this.rowRoLiquid.length }}
                    </span>
                    รายการ</span
                  >
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>ค่าของเหลว</th>
                          <th style="text-align: center">ราคา/หน่วย</th>
                          <th>จำนวน</th>
                          <th style="text-align: center">มูลค่า</th>
                          <th style="text-align: center">ส่วนลด</th>
                          <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                          <th style="text-align: center">สุทธิ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(liquidItem, index) in rowRoLiquid"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td scope="row">
                            <multiselect
                              class="part"
                              type="search"
                              :options="rowLiquid"
                              v-model="liquidItem.selectLiquid"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getLiquid($event)"
                              @input="
                                upsertLiquid(
                                  liquidItem.selectLiquid,
                                  liquidItem
                                )
                              "
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                              :class="{
                                'is-invalid':
                                  form && $v.liquidItem.partId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>

                              <div
                                v-if="form && $v.liquidItem.partId.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.liquidItem.partId.required">{{
                                  message
                                }}</span>
                              </div>
                            </multiselect>
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="liquidItem.pricePerOne"
                              class="form-control text-end number"
                              @input="totalLiquid(liquidItem)"
                              @change="totalLiquid(liquidItem)"
                              @keyup.enter="
                                updateRoLiquid(
                                  liquidItem.partId,
                                  liquidItem.pricePerOne,
                                  liquidItem.amount,
                                  liquidItem.totalPrice,
                                  liquidItem.dcPer,
                                  liquidItem.dcPrice,
                                  liquidItem.netPrice
                                )
                              "
                              :class="{
                                'is-invalid':
                                  form && $v.liquidItem.pricePerOne.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.liquidItem.pricePerOne.$error"
                              class="invalid-tooltip"
                            >
                              <span
                                v-if="!$v.liquidItem.pricePerOne.required"
                                >{{ message }}</span
                              >
                            </div>
                          </td>
                          <td class="">
                            <input
                              type="number"
                              @keyup.enter="
                                updateRoLiquid(
                                  liquidItem.partId,
                                  liquidItem.pricePerOne,
                                  liquidItem.amount,
                                  liquidItem.totalPrice,
                                  liquidItem.dcPer,
                                  liquidItem.dcPrice,
                                  liquidItem.netPrice
                                )
                              "
                              min="0"
                              v-model="liquidItem.amount"
                              @input="totalLiquid(liquidItem)"
                              @change="totalLiquid(liquidItem)"
                              class="form-control text-end Dc"
                              :class="{
                                'is-invalid':
                                  form && $v.liquidItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.liquidItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.liquidItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="liquidItem.totalPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="liquidItem.dcPer"
                              class="form-control text-end Dc"
                              @input="totalLiquid(liquidItem)"
                              @change="totalLiquid(liquidItem)"
                              @keyup.enter="
                                updateRoLiquid(
                                  liquidItem.partId,
                                  liquidItem.pricePerOne,
                                  liquidItem.amount,
                                  liquidItem.totalPrice,
                                  liquidItem.dcPer,
                                  liquidItem.dcPrice,
                                  liquidItem.netPrice
                                )
                              "
                              append=".00"
                              :class="{
                                'is-invalid':
                                  form && $v.liquidItem.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.liquidItem.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.liquidItem.dcPer.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="liquidItem.dcPrice"
                              class="form-control text-end number"
                            />
                          </td>

                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="liquidItem.netPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td>
                            <ul class="list-inline mb-0 action">
                              <li class="list-inline-item">
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="alertLiquid(liquidItem, index)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="text-end">
                            มูลค่ารวม :
                          </th>
                          <td class="text-end">
                            {{
                              Number(this.roLiquidTotalPrice).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ this.roLiquidDcPer }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดยอดรวม(บาท) :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roLiquidDcPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            สุทธิรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roLiquidNetPrice).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div class="col-md-6 ml-4">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="addformLiquid()"
                        >
                          <i class="mdi mdi-plus me-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab>
            <!-- งานนอก -->
            <b-tab>
              <template v-slot:title>
                <span class="d-none d-sm-block">งานนอก</span>
              </template>
              <div class="p-4 border-top">
                <form class="needs-validation" @submit.prevent="osArray">
                  <div class="row p-4">
                    <div class="col-md-6 col-sm-6">
                      <strong><code>* </code>ประเภทส่วนลด</strong>

                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="os.bath"
                          id="isWash"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="1"
                          unchecked-value="0"
                          @input="getRoOs()"
                          plain
                          >บาท</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="os.per"
                          id="isWash2"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="2"
                          unchecked-value="0"
                          @input="getRoOs()"
                          plain
                          >เปอร์เซ็น</b-form-radio
                        >
                      </div>
                    </div>
                  </div>
                  <span
                    >รายการงานนอก
                    <span class="badge bg-info font-size-12 ms-2"
                      >{{ this.rowRoOs.length }}
                    </span>
                    รายการ</span
                  >
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>ค่างานนอก</th>
                          <th style="text-align: center">ราคา/หน่วย</th>
                          <th>จำนวน</th>
                          <th style="text-align: center">มูลค่า</th>
                          <th style="text-align: center">ส่วนลด</th>
                          <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                          <th style="text-align: center">สุทธิ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(osItem, index) in rowRoOs" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td scope="row">
                            <multiselect
                              class="part"
                              type="search"
                              :options="rowOs"
                              v-model="osItem.selectOs"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getOs($event)"
                              @input="upsertOs(osItem.selectOs, osItem)"
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                              :class="{
                                'is-invalid': form && $v.osItem.osId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>

                              <div
                                v-if="form && $v.osItem.osId.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.osItem.osId.required">{{
                                  message
                                }}</span>
                              </div>
                            </multiselect>
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="osItem.pricePerOne"
                              class="form-control text-end number"
                              @input="totalOs(osItem)"
                              @change="totalOs(osItem)"
                              @keyup.enter="
                                updateRoOs(
                                  osItem.osId,
                                  osItem.pricePerOne,
                                  osItem.amount,
                                  osItem.totalPrice,
                                  osItem.dcPer,
                                  osItem.dcPrice,
                                  osItem.netPrice
                                )
                              "
                              :class="{
                                'is-invalid':
                                  form && $v.osItem.pricePerOne.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.osItem.pricePerOne.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.osItem.pricePerOne.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="">
                            <input
                              type="number"
                              @keyup.enter="
                                updateRoOs(
                                  osItem.osId,
                                  osItem.pricePerOne,
                                  osItem.amount,
                                  osItem.totalPrice,
                                  osItem.dcPer,
                                  osItem.dcPrice,
                                  osItem.netPrice
                                )
                              "
                              v-model="osItem.amount"
                              @input="totalOs(osItem)"
                              @change="totalOs(osItem)"
                              class="form-control text-end Dc"
                              :class="{
                                'is-invalid': form && $v.osItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.osItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.osItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="osItem.totalPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td class="">
                            <input
                              v-model="osItem.dcPer"
                              class="form-control text-end Dc"
                              @keyup.enter="
                                updateRoOs(
                                  osItem.osId,
                                  osItem.pricePerOne,
                                  osItem.amount,
                                  osItem.totalPrice,
                                  osItem.dcPer,
                                  osItem.dcPrice,
                                  osItem.netPrice
                                )
                              "
                              @input="totalOs(osItem)"
                              @change="totalOs(osItem)"
                              append=".00"
                              :class="{
                                'is-invalid': form && $v.osItem.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.osItem.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.osItem.dcPer.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="osItem.dcPrice"
                              class="form-control text-end number"
                            />
                          </td>

                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="osItem.netPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td>
                            <ul class="list-inline mb-0 action">
                              <li class="list-inline-item">
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="alertOs(osItem, index)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="text-end">
                            มูลค่ารวม :
                          </th>
                          <td class="text-end">
                            {{ Number(this.roOsTotalPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ this.roOsDcPer }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดยอดรวม(บาท) :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roOsDcPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            สุทธิรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roOsNetPrice).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div class="col-md-6 ml-4">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="addformOs()"
                        >
                          <i class="mdi mdi-plus me-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab>
            <!-- อื่นๆ -->
            <b-tab>
              <template v-slot:title>
                <span class="d-none d-sm-block">อื่นๆ</span>
              </template>
              <div class="p-4 border-top">
                <form class="needs-validation" @submit.prevent="extraArray">
                  <div class="row p-4">
                    <div class="col-md-6 col-sm-6">
                      <strong><code>* </code>ประเภทส่วนลด</strong>

                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="extra.bath"
                          id="isWash"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="1"
                          unchecked-value="0"
                          @input="getRoExtra()"
                          plain
                          >บาท</b-form-radio
                        >
                      </div>
                      <div class="custom-radio form-check form-check-inline">
                        <b-form-radio
                          v-model="extra.per"
                          id="isWash2"
                          name="outer-group[0][isWash]"
                          class="mb-3"
                          value="2"
                          unchecked-value="0"
                          @input="getRoExtra()"
                          plain
                          >เปอร์เซ็น</b-form-radio
                        >
                      </div>
                    </div>
                  </div>
                  <span
                    >รายการอื่นๆ
                    <span class="badge bg-info font-size-12 ms-2"
                      >{{ this.rowRoExtra.length }}
                    </span>
                    รายการ</span
                  >
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>ค่าอื่นๆ</th>
                          <th style="text-align: center">ราคา/หน่วย</th>
                          <th>จำนวน</th>
                          <th style="text-align: center">มูลค่า</th>
                          <th style="text-align: center">ส่วนลด</th>
                          <th style="text-align: center">ส่วนลดยอด(บาท)</th>
                          <th style="text-align: center">สุทธิ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(extraItem, index) in rowRoExtra"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td scope="row">
                            <multiselect
                              class="part"
                              type="search"
                              :options="rowExtra"
                              v-model="extraItem.selectExtra"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getExtra($event)"
                              @input="
                                upsertExtra(extraItem.selectExtra, extraItem)
                              "
                              :show-labels="false"
                              label="nameTh"
                              placeholder=""
                              :class="{
                                'is-invalid':
                                  form && $v.extraItem.extraId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>

                              <div
                                v-if="form && $v.extraItem.extraId.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.extraItem.extraId.required">{{
                                  message
                                }}</span>
                              </div>
                            </multiselect>
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="extraItem.pricePerOne"
                              class="form-control text-end number"
                              @input="totalExtra(extraItem)"
                              @change="totalExtra(extraItem)"
                              @keyup.enter="
                                updateRoExtra(
                                  extraItem.extraId,
                                  extraItem.pricePerOne,
                                  extraItem.amount,
                                  extraItem.totalPrice,
                                  extraItem.dcPer,
                                  extraItem.dcPrice,
                                  extraItem.netPrice
                                )
                              "
                              :class="{
                                'is-invalid':
                                  form && $v.extraItem.pricePerOne.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.extraItem.pricePerOne.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.extraItem.pricePerOne.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="">
                            <input
                              type="number"
                              @input="totalExtra(extraItem)"
                              @change="totalExtra(extraItem)"
                              @keyup.enter="
                                updateRoExtra(
                                  extraItem.extraId,
                                  extraItem.pricePerOne,
                                  extraItem.amount,
                                  extraItem.totalPrice,
                                  extraItem.dcPer,
                                  extraItem.dcPrice,
                                  extraItem.netPrice
                                )
                              "
                              v-model="extraItem.amount"
                              class="form-control text-end Dc"
                              :class="{
                                'is-invalid':
                                  form && $v.extraItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.extraItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.extraItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="extraItem.totalPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td class="">
                            <input
                              min="0"
                              v-model="extraItem.dcPer"
                              class="form-control text-end Dc"
                              @input="totalExtra(extraItem)"
                              @change="totalExtra(extraItem)"
                              @keyup.enter="
                                updateRoExtra(
                                  extraItem.extraId,
                                  extraItem.pricePerOne,
                                  extraItem.amount,
                                  extraItem.totalPrice,
                                  extraItem.dcPer,
                                  extraItem.dcPrice,
                                  extraItem.netPrice
                                )
                              "
                              append=".00"
                              :class="{
                                'is-invalid': form && $v.extraItem.dcPer.$error,
                              }"
                            />
                            <div
                              v-if="form && $v.extraItem.dcPer.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.extraItem.dcPer.required">{{
                                message
                              }}</span>
                            </div>
                          </td>
                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="extraItem.dcPrice"
                              class="form-control text-end number"
                            />
                          </td>

                          <td class="" style="text-align: center">
                            <input
                              disabled
                              v-model="extraItem.netPrice"
                              class="form-control text-end number"
                            />
                          </td>
                          <td>
                            <ul class="list-inline mb-0 action">
                              <li class="list-inline-item">
                                <a
                                  class="px-2 text-danger"
                                  v-b-tooltip.hover
                                  title="Delete"
                                  @click="alertExtra(extraItem, index)"
                                >
                                  <i class="uil uil-trash-alt font-size-18"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="text-end">
                            มูลค่ารวม :
                          </th>
                          <td class="text-end">
                            {{
                              Number(this.roExtraTotalPrice).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ this.roExtraDcPer }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            ส่วนลดยอดรวม(บาท) :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roExtraDcPrice).toLocaleString() }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" colspan="6" class="border-0 text-end">
                            สุทธิรวม :
                          </th>
                          <td class="border-0 text-end">
                            {{ Number(this.roExtraNetPrice).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div class="col-md-6 ml-4">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="addformExtra()"
                        >
                          <i class="mdi mdi-plus me-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab>
          </b-tabs>

          <!-- สรุปยอดใบสั่งซ่อม -->
          <div class="card" v-if="isHidden">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <!-- <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="
                          avatar-title
                          rounded-circle
                          bg-soft-primary
                          text-primary
                        "
                      >
                       
                      </div>
                    </div>
                  </div> -->
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">สรุปยอดการแจ้งซ่อม</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-3"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <div class="container">
                  <div class="row">
                    <div class="col-md-4 col-sm-3 text-end">
                      <p>รวมทั้งหมด :</p>
                      <p>ส่วนลด(%) :</p>
                      <p>ส่วนลด(บาท) :</p>
                      <p>ค่าสุทธิ :</p>
                      <p>ภาษี (%) :</p>
                      <p>ภาษี (บาท) :</p>
                      <p>ค่าสุทธิรวม :</p>

                      <p>หัก ณ ที่จ่าย(%) :</p>
                      <p>หัก ณ ที่จ่าย(บาท) :</p>
                    </div>

                    <div class="col-md-2 col-sm-4">
                      <p class="text-end">
                        <strong @change="calculateTotal"
                          >{{ this.roTotalPrice }}
                        </strong>
                      </p>
                      <div class="col-md-12 col-sm-12 mt-3">
                        <input
                          v-model="roDcPer"
                          class="form-control text-end"
                          @input="calculateTotal"
                          @change="calculateTotal"
                          @keyup.enter="
                            updateRoPrice(repairOrder.svId, repairOrder.roId)
                          "
                          append=".00"
                        />
                      </div>
                      <p class="text-end">
                        <strong>{{ this.roDcPrice }}</strong>
                      </p>
                      <p class="text-end">
                        <strong>{{
                          Number(this.roNetPrice).toLocaleString()
                        }}</strong>
                      </p>
                      <p class="text-end">
                        <strong>{{ this.roVatPer }}</strong>
                      </p>
                      <p class="text-end">
                        <strong>{{
                          Number(this.roVatPrice).toLocaleString()
                        }}</strong>
                      </p>
                      <p class="text-end">
                        <strong>{{
                          Number(this.roGrandTotalPrice).toLocaleString()
                        }}</strong>
                      </p>

                      <div class="col-md-12 col-sm-12">
                        <multiselect
                          class="text-end"
                          id="roWhtPer"
                          v-model="roWhtPer"
                          :options="optionsRoWhtPer"
                          track-by="name"
                          label="name"
                          :show-labels="false"
                          @change="calculateTotal"
                          @input="
                            updateRoPrice(
                              repairOrder.svId,
                              repairOrder.roId,
                              roWhtPer
                            )
                          "
                        ></multiselect>
                      </div>
                      <p class="text-end">
                        <strong>{{ this.roWhtPrice }}</strong>
                      </p>

                      <br />
                    </div>
                    <div class="col-md-5 col-sm-5 mb-3">
                      <div class="row align-items-center m-0 mt-5 border-top">
                        <div class="col align-items-center mt-10">
                          <div
                            class="row bg-dark text-white m-0"
                            style="border-radius: 10px; margin: auto"
                          >
                            <div class="col-md-4">ยอดการชำระ</div>

                            <div class="col-md-6 font-size-24 text-end">
                              <strong>{{
                                Number(this.roPaymentAmount).toLocaleString()
                              }}</strong>
                            </div>
                            <div class="col-md-2 text-end">บาท</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <button
                          class="btn btn-success float-end"
                          @click="
                            updateRoConfirm(repairOrder.svId, repairOrder.roId)
                          "
                        >
                          บันทึกข้อมูลอีกครั้ง
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </b-overlay>
      </b-tabs>
    </div>
    <!-- เปิดหัว -->
    <b-modal
      ref="modalCreateRo"
      id="modalCreateRo"
      title="สร้างใบแจ้งซ่อม"
      hide-footer
      size="lg"
      centb-modalered
    >
      <div class="card-body">
        <form class="needs-validation" @submit.prevent="formDis">
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="mb-3 position-relative">
                <label for="mileNo">เลขกิโลเมตร:</label>
                <input
                  id="mileNo"
                  v-model="formdis.mileNo"
                  type="text"
                  class="form-control"
                  value="Otto"
                />
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="mb-3 position-relative">
                <code>* </code><label for="roType">ประเภท:</label>
                <multiselect
                  id="roType"
                  v-model="formdis.roType"
                  :options="optionRoType"
                  :class="{
                    'is-invalid': submitform && $v.formdis.roType.$error,
                  }"
                  open-direction="bottom"
                  track-by="id"
                  label="id"
                  :show-labels="false"
                >
                </multiselect>
                <div
                  v-if="submitform && $v.formdis.roType.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.formdis.roType.required">{{ message }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="roDate">วันที่สั่งซ่อม:</label>
                <!-- disabled="disabled"  -->
                <date-picker
                  v-model="formdis.roDate"
                  :first-day-of-week="1"
                  format="YYYY-MM-DD"
                  value-type="format"
                  lang="en"
                  :class="{
                    'is-invalid': submitform && $v.formdis.roDate.$error,
                  }"
                ></date-picker>
                <div
                  v-if="submitform && $v.formdis.roDate.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.formdis.roDate.required">{{ message }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="mb-2">
                <label class="form-label d-block mb-3">ลูกค้านัดหมาย:</label>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isBooking"
                    id="isBooking"
                    name="outer-group[0][isBooking]"
                    class="mb-3"
                    plain
                    value="1"
                    unchecked-value="0"
                    >ใช่</b-form-radio
                  >
                </div>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isBooking"
                    id="isBooking2"
                    name="outer-group[0][isBooking]"
                    class="mb-3"
                    value="0"
                    plain
                    >ไม่ใช่</b-form-radio
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="mb-2">
                <label class="form-label d-block mb-3">ล้างรถ:</label>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isWash"
                    id="isWash"
                    name="outer-group[0][isWash]"
                    class="mb-3"
                    value="1"
                    unchecked-value="0"
                    plain
                    >ล้าง</b-form-radio
                  >
                </div>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isWash"
                    id="isWash2"
                    name="outer-group[0][isWash]"
                    class="mb-3"
                    value="0"
                    unchecked-value="0"
                    plain
                    >ไม่ล้าง</b-form-radio
                  >
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="mb-2">
                <label class="form-label d-block mb-3">สถานะ:</label>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isWaiting"
                    id="isWaiting"
                    name="outer-group[0][isWaiting]"
                    class="mb-3"
                    value="1"
                    unchecked-value="0"
                    plain
                    >รอ</b-form-radio
                  >
                </div>
                <div class="custom-radio form-check form-check-inline">
                  <b-form-radio
                    v-model="formdis.isWaiting"
                    id="isWaiting2"
                    name="outer-group[0][isWaiting]"
                    class="mb-3"
                    value="0"
                    unchecked-value="0"
                    plain
                    >ไม่รอ</b-form-radio
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="mb-3 position-relative">
                <label for="note">หมายเหตุ:</label>
                <!-- disabled="disabled"  -->
                <textarea
                  v-model="formdis.note"
                  class="form-control"
                  name="textarea"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row"></div>
    </b-modal>

    <b-modal
      ref="modalCreateRepair"
      id="modalCreateRepair"
      title="เข้าโรงซ่อม"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <form class="needs-validation" @submit.prevent="formRepair">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="mb-3 position-relative">
                <label for="priMechanicId">ช่างที่รับผิดชอบ:</label>
                <multiselect
                  id="priMechanicId"
                  type="search"
                  v-model="repair.priMechanicId"
                  :options="rowUser"
                  :custom-label="customLabel"
                  :loading="loading"
                  :close-on-select="true"
                  :internal-search="true"
                  open-direction="bottom"
                  @search-change="getUser"
                  placeholder=""
                  :show-labels="false"
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.name }}&nbsp;</span
                      ><span class="option__small">{{
                        props.option.familyName
                      }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="mb-3 position-relative">
                <code>* </code><label for="fslotId">ช่องจอดและ slot:</label>
                <multiselect
                  id="fslotId"
                  type="search"
                  v-model="repair.fslotId"
                  :options="rowFlow"
                  :class="{
                    'is-invalid': submit && $v.repair.fslotId.$error,
                  }"
                  open-direction="bottom"
                  track-by="nameTh"
                  label="nameTh"
                  :show-labels="false"
                  @search-change="getFlow"
                  placeholder=""
                >
                  <span slot="noResult">ไม่พบข้อมูล</span>
                </multiselect>
                <div
                  v-if="submit && $v.repair.fslotId.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.repair.fslotId.required">{{ message }}</span>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-sm-12">
              <div class="row align-items-center ml-5">
                <label class="typo-label" for="ajax">ใบแจ้งซ่อม:</label>

                <div
                  class="col-md-6 col-lg-5 m-2"
                  v-for="(roItem, index) in rowRoSearch"
                  :key="index"
                >
                  <b-form-checkbox
                    v-model="selectRo"
                    :value="roItem.roId"
                    class="mb-3 me-2"
                    plain
                    :state="state"
                    checked
                    :class="{
                      'is-invalid': submitform,
                    }"
                  >
                    {{ roItem.roCode }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row"></div>
    </b-modal>
  </b-tab>
</template>

<script>
import {
  required,
  // numeric,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // minValue,
  // maxValue,
  // url,
  // alphaNum
} from "vuelidate/lib/validators";

import appConfig from "@/app.config";
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
// import roComponents from "./roComponent";
// import editTableComponent from "@/components/editTableComponent";
// import tableData from "@/components/tablecomponent";

export default {
  components: {
    // editTableComponent,
    // tableData,
    Multiselect,
    DatePicker,
  },
  name: "Tabcomponents",

  page: {
    title: "การเข้ารับบริการ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      beforWage: "",
      rowParts: [],
      selectedRow: {},
      selectedCell: null,
      loading: false,
      err: "โปรดกรอกข้อมูลให้ถูกต้อง",
      message: "โปรดระบุข้อมูล",
      title: "เพิ่มข้อมูลค่าแรง",
      items: [
        {
          text: "ข้อมูลค่าแรง",
          href: "/branch-wages",
        },
        {
          text: "เพิ่มข้อมูลค่าแรง",
          active: true,
        },
      ],

      statusSv: "",
      rowsSv: [],
      branchIdSv: "",
      isHidden: false,
      selectMode: "multi",
      selected: [],
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      // this.$route.params.roId
      roId: "",
      wageId: this.$route.params.wageId,
      svId: this.$route.params.svId,
      svIdCre: this.$route.params.svId,
      rowRo: [],
      svIdCode: this.$route.params.svId,
      //master os
      roOsTotalPrice: 0,
      roOsDcPer: 0,
      roOsDcPrice: 0,
      roOsNetPrice: 0,
      //master liquid
      roLiquidTotalPrice: 0,
      roLiquidDcPer: 0,
      roLiquidDcPrice: 0,
      roLiquidNetPrice: 0,
      //master extra
      roExtraTotalPrice: 0,
      roExtraDcPer: 0,
      roExtraDcPrice: 0,
      roExtraNetPrice: 0,
      //master wage
      totalNetPrice: "",
      roWageTotalPrice: 0,
      roWageDcPer: 0,
      roWageDcPrice: 0,
      roWagePrice: 0,
      //master part
      roPartTotalPrice: 0,
      roPartDcPer: 0,
      roPartDcPrice: 0,
      roPartNetPrice: 0,
      //master
      roTotalPrice: 0,
      roDcPer: 0,
      roDcPrice: 0,
      roNetPrice: 0,
      roVatPer: 7,
      roVatPrice: 0,
      roGrandTotalPrice: 0,
      roWhtPer: 0,
      roWhtPrice: 0,
      roPaymentAmount: 0,
      os: {
        osId: "",
        selectOs: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      liquid: {
        partId: "",
        selectLiquid: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      extra: {
        extraId: "",
        selectExtra: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },

      wage: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      part: {
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        bath: "",
        per: "",
      },
      repair: {
        priMechanicId: "",
        pslotId: "",
        fslotId: "",
      },
      selectRoitem: [],
      selectRo: [],
      rowRoSearch: [],
      partArray: [],
      wageArray: [],
      osArray: [],
      liquidArray: [],
      extraArray: [],
      optionTypeDc: [
        {
          name: "บาท",
          bath: "bath",
        },
        {
          name: "เปอรเซ็น",
          per: "per",
        },
      ],

      optionsRoWhtPer: [
        {
          name: "ไม่ระบุ",
          id: 0,
        },
        {
          name: "3%",
          id: 3,
        },
      ],

      status: "",
      mileNo: "",
      toastCount: 0,
      roWageId: "",
      rowUser: [],
      rowParking: [],
      rowFlow: [],
      overlayFlag: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 30,
      pageOptions: [10, 25, 50, 100],
      filter: {
        priMechanicId: "",
      },
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      optionsWage: [],
      optionsGroupId: [],
      distances: [],
      rowSearch: [],
      wageOptions: [],
      wageOptionsRo: [],
      repairOrder: [],
      rowRoWages: [],
      rowRoParts: [],
      rowRoLiquid: [],
      rowRoExtra: [],
      rowRoOs: [],
      rowLiquid: [],
      rowOs: [],
      rowExtra: [],
      roReId: [],
      roShow: [],
      rowsTest2: [
        {
          id: "1",
        },
      ],
      optionRoType: [
        {
          id: "GS",
        },
        {
          id: "BP",
        },
        {
          id: "SLA",
        },
      ],
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      options: [],
      formdis: {
        svId: "",
        roType: "",
        roDate: "",
        isBooking: "",
        isWash: "",
        isWaiting: "",
        distanceId: "",
        mileNo: "",
        note: "",
      },
      formdisUp: {
        roTypeUp: "",
        roDateUp: "",
        isBookingUp: "",
        isWashUp: "",
        isWaitingUp: "",
        distanceIdUp: "",
        mileNoUp: "",
        noteUp: "",
      },

      submitform: false,
      submitformUp: false,
      submit: false,
      form: false,
    };
  },

  validations: {
    formdis: {
      roType: {
        required,
      },
      roDate: { required },
    },
    formdisUp: {
      roTypeUp: {
        required,
      },
      roDateUp: { required },
    },
    wageItem: {
      wageId: {
        required,
      },
      wage_Id: {
        required,
      },
      amount: {
        required,
      },
      nameTh: {
        required,
      }, //
      totalPrice: {
        required,
      },
      price: {
        required,
      }, //
      pricePerOne: {
        required,
      },
      dcPer: {
        required,
      },
      dcPrice: { required },
      netPrice: { required },
    },
    partItem: {
      partId: {
        required,
      },

      amount: {
        required,
      },
      nameTh: {
        required,
      }, //
      totalPrice: {
        required,
      },
      price: {
        required,
      }, //
      pricePerOne: {
        required,
      },
      dcPer: {
        required,
      },
      dcPrice: { required },
      netPrice: { required },
    },
    repair: {
      fslotId: {
        required,
      },
      // selectRo: { required },
    },
  },
  mounted() {
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // this.token = `${user[appConfig.storageTokenKeyName]}`;
  },
  computed: {
    state() {
      return this.selectRo.length >= 1;
    },
  },
  created() {
    this.getDataSv();
    this.getRoSearch();
    this.getLocalData();
    this.getRo();
    this.rowRoWages.push(this.wage);
    this.rowRoParts.push(this.part);
    this.rowRoLiquid.push(this.liquid);
    this.rowRoOs.push(this.os);
    this.rowRoExtra.push(this.extra);
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    limitText(count) {
      return `and ${count} other Ro`;
    },
    clearAll() {
      this.selectRo = [];
    },
    customLabel({ name, familyName }) {
      return `${name != null ? name : ""}  ${
        familyName != null ? familyName : ""
      }`;
    },
    addformWage() {
      this.rowRoWages.push({
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformPart() {
      this.rowRoParts.push({
        partId: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformOs() {
      this.rowRoOs.push({
        osId: "",
        selectOs: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformLiquid() {
      this.rowRoLiquid.push({
        partId: "",
        selectLiquid: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    addformExtra() {
      this.rowRoExtra.push({
        extraId: "",
        selectExtra: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
      });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
    },

    formDis() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.formdis.$invalid != true) {
        this.postRo();
      }
    },
    formDisUp() {
      this.$v.$touch();
      this.submitformUp = true;
      this.updateRo();
    },
    // repair
    formRepair() {
      this.submit = true;
      this.$v.$touch();
      if (this.$v.repair.$invalid != true) {
        this.postRepair();
        this.$refs.modalCreateRepair.hide();
      }
    },
    getRoPrint: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/service/ro/print", {
          params: {
            svId: atob(this.svId),
            roId: this.roShowId,
          },
        })
        .then((response) => {
          this.dataPrint = response.data.url;
          window.location = this.dataPrint;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataSv: function () {
      this.overlayFlag = true;
      if (this.svIdCre != null) {
        useNetw
          .get("api/service/show", {
            params: {
              svId: atob(this.svIdCre),
            },
          })
          .then((response) => {
            this.rowsSv = response.data.data;
            this.branchIdSv = response.data.data.branchId;
            this.statusSv = response.data.data.status;
            this.getFlow();
            this.getUser();
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push("/login");
            }
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
      if (this.svId != null) {
        useNetw
          .get("api/service/show", {
            params: {
              svId: atob(this.svId),
            },
          })
          .then((response) => {
            this.rowsSv = response.data.data;
            this.branchIdSv = response.data.data.branchId;
            this.getFlow();
            this.getUser();
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push("/login");
            }
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    getUser: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/user", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: [this.branchIdSv],
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getFlow: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/available-slots", {
          params: {
            branchId: this.branchIdSv,
          },
        })
        .then((response) => {
          this.rowFlow = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getParking: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.branchIdSv,
          },
        })
        .then((response) => {
          this.rowParking = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    postRepair: function () {
      this.overlayFlag = true;

      useNetw
        .post(
          "api/service/repair/store",

          {
            svId: atob(this.svIdCode),
            roId: this.selectRo,
            priMechanicId: JSON.stringify(this.repair.priMechanicId.userId),
            pslotId: JSON.stringify(this.repair.fslotId.pslotId),
            fslotId: JSON.stringify(this.repair.fslotId.fslotId),
          }
        )
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs.modalCreateRepair.hide();
          this.getDataSv();
          this.isHidden = true;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          //   this.getRoWage();
          // this.getRoPart();
          // this.$router.push({ name: "branch-wages" });
        });
    },
    getRo: function () {
      // this.overlayFlag = true;
      // var encodedString = btoa(response.data.svId);
      // const binaryString  = atob(this.svIdCode)
      // this.svIdCode = binaryString;
      useNetw
        .get("api/service/ro", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getRoSearch: function () {
      // this.overlayFlag = true;

      useNetw
        .get("api/service/ro", {
          params: {
            page: this.currentPage,
            perPage: 100,
            svId: atob(this.svIdCode),
          },
        })
        .then((response) => {
          this.rowRoSearch = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getRoWage: function () {
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/wages", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoWages = response.data.data;
          this.rowRoWages.forEach((element, index) => {
            // เข้าถึง array
            this.rowRoWages[index].selectWage = {
              wageId: element.wageId,
              nameTh: element.wageNameTh,
            };
          });
          this.totalWage(this.rowRoWages);
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
          this.calculateTotal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRoPart: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/parts", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoParts = response.data.data;
          this.rowRoParts.forEach((element, index) => {
            this.rowRoParts[index].selectPart = {
              // เข้าถึง array
              partId: element.partId,
              nameTh: element.partNameTh,
            };
          });
          this.totalPart(this.rowRoParts);
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
          this.calculateTotal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRoOs: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/os", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoOs = response.data.data;
          this.rowRoOs.forEach((element, index) => {
            this.rowRoOs[index].selectOs = {
              // เข้าถึง array
              osId: element.osId,
              nameTh: element.osNameTh,
            };
          });
          this.totalOs(this.rowRoOs);
          this.calculateTotal();
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRoLiquid: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/liquid", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoLiquid = response.data.data;
          this.rowRoLiquid.forEach((element, index) => {
            this.rowRoLiquid[index].selectLiquid = {
              // เข้าถึง array
              partId: element.partId,
              nameTh: element.partNameTh,
            };
          });
          this.totalLiquid(this.rowRoLiquid);
          this.calculateTotal();
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRoExtra: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/ro/extra", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: atob(this.svIdCode),
            roId: this.roShowId != null ? this.roShowId : this.roId,
          },
        })
        .then((response) => {
          this.rowRoExtra = response.data.data;
          this.rowRoExtra.forEach((element, index) => {
            this.rowRoExtra[index].selectExtra = {
              // เข้าถึง array
              extraId: element.extraId,
              nameTh: element.extraNameTh,
            };
          });
          this.totalExtra(this.rowRoExtra);
          this.calculateTotal();
          this.calculateTotalExtra();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalPart();
          this.calculateTotalWage();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getWage: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/branch-wage", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: [this.branchIdSv],
            nameTh: event,
            // selectWage:this.filter.selectWage,
            // selectWage: this.wages.wageId,
          },
        })
        .then((response) => {
          this.wageOptions = response.data.data;
          this.wageId = response.data.wageId;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getParts: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdSv],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
          this.partId = response.data.partId;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOs: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/branch-os", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdSv],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowOs = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLiquid: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/branch-liquid", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdSv],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowLiquid = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getExtra: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/branch-extra", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: [this.branchIdSv],
            nameTh: event,
          },
        })
        .then((response) => {
          this.rowExtra = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    postRo: function () {
      this.overlayFlag = true;
      const bodyFormData = new FormData();
      this.rowRoWages.push(this.wage);
      this.rowRoParts.push(this.part);
      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roType", this.formdis.roType.id);
      bodyFormData.append("roDate", this.formdis.roDate);
      bodyFormData.append(
        "isBooking",
        this.formdis.isBooking != null ? this.formdis.isBooking : 0
      );
      bodyFormData.append(
        "isWash",
        this.formdis.isWash != null ? this.formdis.isWash : 0
      );
      bodyFormData.append(
        "isWaiting",
        this.formdis.isWaiting != null ? this.formdis.isWaiting : 0
      );

      bodyFormData.append("mileNo", this.formdis.mileNo);
      bodyFormData.append("note", this.formdis.note);

      useNetw
        .post("api/service/ro/store", bodyFormData)
        .then((response) => {
          this.roId = response.data.roId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.getRoWage(this.roId);
          this.getRoPart(this.roId);
          this.getRoLiquid(this.roId);
          this.getRoOs(this.roId);
          this.getRoExtra(this.roId);
          this.$refs.modalCreateRo.hide();
          this.isHidden = true;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          this.getRo();
          this.getRoShow(this.roId);
          this.getRoSearch();
          //   this.getRoWage();
          // this.getRoPart();
          // this.$router.push({ name: "branch-wages" });
        });
    },

    postRoWage: function (wage, price, id) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();
      this.beforWage = wage.wageId;
      this.wage_Id = id;
      wage.pricePerOne = price;

      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roId", this.roShow);
      bodyFormData.append("wageId", this.wage_Id); //JSON.stringify(this.wageId));
      bodyFormData.append("amount", wage.amount);
      bodyFormData.append("totalPrice", wage.totalPrice);
      bodyFormData.append("pricePerOne", wage.pricePerOne);
      bodyFormData.append("dcPrice", wage.dcPrice);
      bodyFormData.append("dcPer", wage.dcPer);
      bodyFormData.append("netPrice", wage.netPrice);
      // wage
      bodyFormData.append("roWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("roWageDcPer", this.roWageDcPer);
      bodyFormData.append("roWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("roWagePrice", this.roWagePrice);
      // part
      bodyFormData.append("roPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("roPartDcPer", this.roPartDcPer);
      bodyFormData.append("roPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("roPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("roOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("roOsDcPer", this.roOsDcPer);
      bodyFormData.append("roOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("roOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("roLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("roLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("roLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("roLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("roExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("roExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("roExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("roExtraNetPrice", this.roExtraNetPrice);
      // master
      bodyFormData.append("roTotalPrice", this.roTotalPrice);
      bodyFormData.append("roDcPer", this.roDcPer);
      bodyFormData.append("roDcPrice", this.roDcPrice);
      bodyFormData.append("roNetPrice", this.roNetPrice);
      bodyFormData.append("roVatPer", this.roVatPer);
      bodyFormData.append("roVatPrice", this.roVatPrice);
      bodyFormData.append("roGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "roWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("roWhtPrice", this.roWhtPrice);
      bodyFormData.append("roPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/service/ro/wage/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.getRoShow(this.roShow);

          // this.$router.push({ name: "branch-wages" });
        });
    },

    postRoPart: function (part) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roId", this.roShow);
      bodyFormData.append("partId", part.partId); //JSON.stringify(this.wageId));
      bodyFormData.append("amount", part.amount);
      bodyFormData.append("totalPrice", part.totalPrice);
      bodyFormData.append("pricePerOne", part.pricePerOne);
      bodyFormData.append("dcPrice", part.dcPrice);
      bodyFormData.append("dcPer", part.dcPer);
      bodyFormData.append("netPrice", part.netPrice);
      bodyFormData.append("roWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("roWageDcPer", this.roWageDcPer);
      bodyFormData.append("roWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("roWagePrice", this.roWagePrice);
      bodyFormData.append("roPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("roPartDcPer", this.roPartDcPer);
      bodyFormData.append("roPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("roPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("roOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("roOsDcPer", this.roOsDcPer);
      bodyFormData.append("roOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("roOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("roLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("roLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("roLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("roLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("roExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("roExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("roExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("roExtraNetPrice", this.roExtraNetPrice);
      bodyFormData.append("roTotalPrice", this.roTotalPrice);
      bodyFormData.append("roDcPer", this.roDcPer);
      bodyFormData.append("roDcPrice", this.roDcPrice);
      bodyFormData.append("roNetPrice", this.roNetPrice);
      bodyFormData.append("roVatPer", this.roVatPer);
      bodyFormData.append("roVatPrice", this.roVatPrice);
      bodyFormData.append("roGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "roWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("roWhtPrice", this.roWhtPrice);
      bodyFormData.append("roPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/service/ro/part/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.getRoWage();

          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postRoLiquid: function (part) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roId", this.roShow);
      bodyFormData.append("partId", part.partId); //JSON.stringify(this.wageId));
      bodyFormData.append("amount", part.amount);
      bodyFormData.append("totalPrice", part.totalPrice);
      bodyFormData.append("pricePerOne", part.pricePerOne);
      bodyFormData.append("dcPrice", part.dcPrice);
      bodyFormData.append("dcPer", part.dcPer);
      bodyFormData.append("netPrice", part.netPrice);
      bodyFormData.append("roWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("roWageDcPer", this.roWageDcPer);
      bodyFormData.append("roWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("roWagePrice", this.roWagePrice);
      bodyFormData.append("roPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("roPartDcPer", this.roPartDcPer);
      bodyFormData.append("roPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("roPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("roOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("roOsDcPer", this.roOsDcPer);
      bodyFormData.append("roOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("roOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("roLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("roLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("roLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("roLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("roExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("roExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("roExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("roExtraNetPrice", this.roExtraNetPrice);
      bodyFormData.append("roTotalPrice", this.roTotalPrice);
      bodyFormData.append("roDcPer", this.roDcPer);
      bodyFormData.append("roDcPrice", this.roDcPrice);
      bodyFormData.append("roNetPrice", this.roNetPrice);
      bodyFormData.append("roVatPer", this.roVatPer);
      bodyFormData.append("roVatPrice", this.roVatPrice);
      bodyFormData.append("roGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "roWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("roWhtPrice", this.roWhtPrice);
      bodyFormData.append("roPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/service/ro/liquid/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.getRoWage();

          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postRoOs: function (os) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roId", this.roShow);
      bodyFormData.append("osId", os.osId); //JSON.stringify(this.wageId));
      bodyFormData.append("amount", os.amount);
      bodyFormData.append("totalPrice", os.totalPrice);
      bodyFormData.append("pricePerOne", os.pricePerOne);
      bodyFormData.append("dcPrice", os.dcPrice);
      bodyFormData.append("dcPer", os.dcPer);
      bodyFormData.append("netPrice", os.netPrice);
      bodyFormData.append("roWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("roWageDcPer", this.roWageDcPer);
      bodyFormData.append("roWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("roWagePrice", this.roWagePrice);
      bodyFormData.append("roPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("roPartDcPer", this.roPartDcPer);
      bodyFormData.append("roPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("roPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("roOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("roOsDcPer", this.roOsDcPer);
      bodyFormData.append("roOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("roOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("roLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("roLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("roLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("roLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("roExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("roExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("roExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("roExtraNetPrice", this.roExtraNetPrice);
      bodyFormData.append("roTotalPrice", this.roTotalPrice);
      bodyFormData.append("roDcPer", this.roDcPer);
      bodyFormData.append("roDcPrice", this.roDcPrice);
      bodyFormData.append("roNetPrice", this.roNetPrice);
      bodyFormData.append("roVatPer", this.roVatPer);
      bodyFormData.append("roVatPrice", this.roVatPrice);
      bodyFormData.append("roGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "roWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("roWhtPrice", this.roWhtPrice);
      bodyFormData.append("roPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/service/ro/os/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.getRoWage();

          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postRoExtra: function (extra) {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // // parse
      bodyFormData.append("svId", atob(this.svIdCode));
      bodyFormData.append("roId", this.roShow);
      bodyFormData.append("extraId", extra.extraId); //JSON.stringify(this.wageId));
      bodyFormData.append("amount", extra.amount);
      bodyFormData.append("totalPrice", extra.totalPrice);
      bodyFormData.append("pricePerOne", extra.pricePerOne);
      bodyFormData.append("dcPrice", extra.dcPrice);
      bodyFormData.append("dcPer", extra.dcPer);
      bodyFormData.append("netPrice", extra.netPrice);
      bodyFormData.append("roWageTotalPrice", this.roWageTotalPrice);
      bodyFormData.append("roWageDcPer", this.roWageDcPer);
      bodyFormData.append("roWageDcPrice", this.roWageDcPrice);
      bodyFormData.append("roWagePrice", this.roWagePrice);
      bodyFormData.append("roPartTotalPrice", this.roPartTotalPrice);
      bodyFormData.append("roPartDcPer", this.roPartDcPer);
      bodyFormData.append("roPartDcPrice", this.roPartDcPrice);
      bodyFormData.append("roPartNetPrice", this.roPartNetPrice);
      // os
      bodyFormData.append("roOsTotalPrice", this.roOsTotalPrice);
      bodyFormData.append("roOsDcPer", this.roOsDcPer);
      bodyFormData.append("roOsDcPrice", this.roOsDcPrice);
      bodyFormData.append("roOsNetPrice", this.roOsNetPrice);
      // liquid
      bodyFormData.append("roLiquidTotalPrice", this.roLiquidTotalPrice);
      bodyFormData.append("roLiquidDcPer", this.roLiquidDcPer);
      bodyFormData.append("roLiquidDcPrice", this.roLiquidDcPrice);
      bodyFormData.append("roLiquidNetPrice", this.roLiquidNetPrice);
      // extra
      bodyFormData.append("roExtraTotalPrice", this.roExtraTotalPrice);
      bodyFormData.append("roExtraDcPer", this.roExtraDcPer);
      bodyFormData.append("roExtraDcPrice", this.roExtraDcPrice);
      bodyFormData.append("roExtraNetPrice", this.roExtraNetPrice);
      bodyFormData.append("roTotalPrice", this.roTotalPrice);
      bodyFormData.append("roDcPer", this.roDcPer);
      bodyFormData.append("roDcPrice", this.roDcPrice);
      bodyFormData.append("roNetPrice", this.roNetPrice);
      bodyFormData.append("roVatPer", this.roVatPer);
      bodyFormData.append("roVatPrice", this.roVatPrice);
      bodyFormData.append("roGrandTotalPrice", this.roGrandTotalPrice);
      bodyFormData.append(
        "roWhtPer",
        this.roWhtPer.id != undefined ? this.roWhtPer.id : 0
      );
      bodyFormData.append("roWhtPrice", this.roWhtPrice);
      bodyFormData.append("roPaymentAmount", this.roPaymentAmount);

      useNetw
        .post("api/service/ro/extra/store", bodyFormData)
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.getRoWage();

          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },

    getRoShow: function (roId) {
      this.overlayFlag = true;
      this.roShowId = roId;
      // const decodedString = atob(this.svIdCode);
      // this.svIdCode = decodedString;
      useNetw
        .get("api/service/ro/show", {
          params: {
            svId: atob(this.svIdCode),
            roId: this.roShowId,
          },
        })
        .then((response) => {
          this.repairOrder = response.data.repairOrder;
          this.status = response.data.repairOrder.status;

          this.service = response.data.service;
          this.roShow = response.data.repairOrder.roId;
          this.svShow = response.data.repairOrder.svId;
          this.getRoWage(this.roShow);
          this.getRoPart(this.roShow);
          this.getRoLiquid(this.roShow);
          this.getRoOs(this.roShow);
          this.getRoExtra(this.roShow);
          this.isHidden = true;
          this.roWhtPer = { name: response.data.repairOrder.whtPer };
          this.roDcPer = response.data.repairOrder.dcPer;
          this.roDcPrice = response.data.repairOrder.dcPrice;
          this.roWhtPrice = response.data.repairOrder.whtPrice;
          this.formdisUp.mileNoUp = response.data.repairOrder.mileNo;
          this.formdisUp.roTypeUp = { id: response.data.repairOrder.roType };
          this.formdisUp.roDateUp = response.data.repairOrder.roDate;
          this.formdisUp.isBookingUp = response.data.repairOrder.isBooking;
          this.formdisUp.isWashUp = response.data.repairOrder.isWash;
          this.formdisUp.isWaitingUp = response.data.repairOrder.isWaiting;
          this.formdisUp.noteUp = response.data.repairOrder.note;

          this.roPartTotalPrice = response.data.repairOrder.partTotalPrice;
          this.roPartDcPer = response.data.repairOrder.partDcPer;
          this.roPartDcPrice = response.data.repairOrder.partDcPrice;
          this.roPartNetPrice = response.data.repairOrder.partNetPrice;

          this.roWageTotalPrice = response.data.repairOrder.wageTotalPrice;
          this.roWageDcPer = response.data.repairOrder.wageDcPer;
          this.roWageDcPrice = response.data.repairOrder.wageDcPrice;
          this.roWagePrice = response.data.repairOrder.wagePrice;

          this.roLiquidTotalPrice = response.data.repairOrder.liquidTotalPrice;
          this.roLiquidDcPer = response.data.repairOrder.liquidDcPer;
          this.roLiquidDcPrice = response.data.repairOrder.liquidDcPrice;
          this.roLiquidNetPrice = response.data.repairOrder.liquidNetPrice;

          this.roOsTotalPrice = response.data.repairOrder.osTotalPrice;
          this.roOsDcPer = response.data.repairOrder.osDcPer;
          this.roOsDcPrice = response.data.repairOrder.osDcPrice;
          this.roOsNetPrice = response.data.repairOrder.osNetPrice;

          this.roExtraTotalPrice = response.data.repairOrder.extraTotalPrice;
          this.roExtraDcPer = response.data.repairOrder.extraDcPer;
          this.roExtraDcPrice = response.data.repairOrder.extraDcPrice;
          this.roExtraNetPrice = response.data.repairOrder.extraNetPrice;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getRoShowDetail: function (svIdCode) {
      this.code = svIdCode;
      this.overlayFlag = true;
      useNetw
        .get("api/service/ro/show", {
          params: {
            svId: atob(this.svIdCode),
            roId: this.roId,
          },
        })
        .then((response) => {
          this.repairOrder = response.data.repairOrder;
          this.service = response.data.service;
          //  this.roId = response.data.roId
          // this.svId = response.data.svId
          this.isHidden = true;
          this.getRoWage(this.roShowId);
          this.getRoPart(this.roShowId);
          this.getRoLiquid(this.roShowId);
          this.getRoOs(this.roShowId);
          this.getRoExtra(this.roShow);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    updateRoWage(wageId, price, amount, totalPrice, dcPer, dcPrice, netPrice) {
      this.wage.wageId = wageId;
      this.wage.pricePerOne = price;
      this.wage.amount = amount;
      this.wage.totalPrice = totalPrice;
      this.wage.dcPer = dcPer;
      this.wage.dcPrice = dcPrice;
      this.wage.netPrice = netPrice;

      useNetw
        .put("api/service/ro/wage/update", {
          svId: atob(this.svIdCode),
          roId: this.roShow,
          wageId: this.wage.wageId,
          amount: this.wage.amount,
          pricePerOne: this.wage.pricePerOne,
          totalPrice: this.wage.totalPrice,
          dcPer: this.wage.dcPer,
          dcPrice: this.wage.dcPrice,
          netPrice: this.wage.netPrice,
          // wage
          roWageTotalPrice: this.roWageTotalPrice,
          roWageDcPer: this.roWageDcPer,
          roWageDcPrice: this.roWageDcPrice,
          roWagePrice: this.roWagePrice,
          // part
          roPartTotalPrice: this.roPartTotalPrice,
          roPartDcPer: this.roPartDcPer,
          roPartDcPrice: this.roPartDcPrice,
          roPartNetPrice: this.roPartNetPrice,
          // os
          roOsTotalPrice: this.roOsTotalPrice,
          roOsDcPer: this.roOsDcPer,
          roOsDcPrice: this.roOsDcPrice,
          roOsNetPrice: this.roOsNetPrice,
          // liquid
          roLiquidTotalPrice: this.roLiquidTotalPrice,
          roLiquidDcPer: this.roLiquidDcPer,
          roLiquidDcPrice: this.roLiquidDcPrice,
          roLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          roExtraTotalPrice: this.roExtraTotalPrice,
          roExtraDcPer: this.roExtraDcPer,
          roExtraDcPrice: this.roExtraDcPrice,
          roExtraNetPrice: this.roExtraNetPrice,
          roTotalPrice: this.roTotalPrice,
          roDcPer: this.roDcPer,
          roDcPrice: this.roDcPrice,
          roNetPrice: this.roNetPrice,
          roVatPer: this.roVatPer,
          roVatPrice: this.roVatPrice,
          roGrandTotalPrice: this.roGrandTotalPrice,
          roWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          roWhtPrice: this.roWhtPrice,
          roPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalWage();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();

          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);

          // this.overlayFlag = false;
        });
    },
    updateRoPart(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice
    ) {
      this.part.partId = partId;
      this.part.pricePerOne = sellPrice;
      this.part.amount = amount;
      this.part.totalPrice = totalPrice;
      this.part.dcPer = dcPer;
      this.part.dcPrice = dcPrice;
      this.part.netPrice = netPrice;
      // this.overlayFlag = true;

      useNetw
        .put("api/service/ro/part/update", {
          svId: atob(this.svIdCode),
          roId: this.roShow,
          partId: this.part.partId,
          amount: this.part.amount,
          pricePerOne: this.part.pricePerOne,
          totalPrice: this.part.totalPrice,
          dcPer: this.part.dcPer,
          dcPrice: this.part.dcPrice,
          netPrice: this.part.netPrice,
          roWageTotalPrice: this.roWageTotalPrice,
          roWageDcPer: this.roWageDcPer,
          roWageDcPrice: this.roWageDcPrice,
          roWagePrice: this.roWagePrice,
          roPartTotalPrice: this.roPartTotalPrice,
          roPartDcPer: this.roPartDcPer,
          roPartDcPrice: this.roPartDcPrice,
          roPartNetPrice: this.roPartNetPrice,
          // os
          roOsTotalPrice: this.roOsTotalPrice,
          roOsDcPer: this.roOsDcPer,
          roOsDcPrice: this.roOsDcPrice,
          roOsNetPrice: this.roOsNetPrice,
          // liquid
          roLiquidTotalPrice: this.roLiquidTotalPrice,
          roLiquidDcPer: this.roLiquidDcPer,
          roLiquidDcPrice: this.roLiquidDcPrice,
          roLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          roExtraTotalPrice: this.roExtraTotalPrice,
          roExtraDcPer: this.roExtraDcPer,
          roExtraDcPrice: this.roExtraDcPrice,
          roExtraNetPrice: this.roExtraNetPrice,
          roTotalPrice: this.roTotalPrice,
          roDcPer: this.roDcPer,
          roDcPrice: this.roDcPrice,
          roNetPrice: this.roNetPrice,
          roVatPer: this.roVatPer,
          roVatPrice: this.roVatPrice,
          roGrandTotalPrice: this.roGrandTotalPrice,
          roWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          roWhtPrice: this.roWhtPrice,
          roPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.overlayFlag = false;
        });
    },
    updateRoLiquid(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice
    ) {
      this.liquid.partId = partId;
      this.liquid.pricePerOne = sellPrice;
      this.liquid.amount = amount;
      this.liquid.totalPrice = totalPrice;
      this.liquid.dcPer = dcPer;
      this.liquid.dcPrice = dcPrice;
      this.liquid.netPrice = netPrice;
      // this.overlayFlag = true;

      useNetw
        .put("api/service/ro/liquid/update", {
          svId: atob(this.svIdCode),
          roId: this.roShow,
          partId: this.liquid.partId,
          amount: this.liquid.amount,
          pricePerOne: this.liquid.pricePerOne,
          totalPrice: this.liquid.totalPrice,
          dcPer: this.liquid.dcPer,
          dcPrice: this.liquid.dcPrice,
          netPrice: this.liquid.netPrice,
          roWageTotalPrice: this.roWageTotalPrice,
          roWageDcPer: this.roWageDcPer,
          roWageDcPrice: this.roWageDcPrice,
          roWagePrice: this.roWagePrice,
          roPartTotalPrice: this.roPartTotalPrice,
          roPartDcPer: this.roPartDcPer,
          roPartDcPrice: this.roPartDcPrice,
          roPartNetPrice: this.roPartNetPrice,
          // os
          roOsTotalPrice: this.roOsTotalPrice,
          roOsDcPer: this.roOsDcPer,
          roOsDcPrice: this.roOsDcPrice,
          roOsNetPrice: this.roOsNetPrice,
          // liquid
          roLiquidTotalPrice: this.roLiquidTotalPrice,
          roLiquidDcPer: this.roLiquidDcPer,
          roLiquidDcPrice: this.roLiquidDcPrice,
          roLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          roExtraTotalPrice: this.roExtraTotalPrice,
          roExtraDcPer: this.roExtraDcPer,
          roExtraDcPrice: this.roExtraDcPrice,
          roExtraNetPrice: this.roExtraNetPrice,
          roTotalPrice: this.roTotalPrice,
          roDcPer: this.roDcPer,
          roDcPrice: this.roDcPrice,
          roNetPrice: this.roNetPrice,
          roVatPer: this.roVatPer,
          roVatPrice: this.roVatPrice,
          roGrandTotalPrice: this.roGrandTotalPrice,
          roWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          roWhtPrice: this.roWhtPrice,
          roPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.overlayFlag = false;
        });
    },
    updateRoOs(osId, sellPrice, amount, totalPrice, dcPer, dcPrice, netPrice) {
      this.os.osId = osId;
      this.os.pricePerOne = sellPrice;
      this.os.amount = amount;
      this.os.totalPrice = totalPrice;
      this.os.dcPer = dcPer;
      this.os.dcPrice = dcPrice;
      this.os.netPrice = netPrice;
      // this.overlayFlag = true;

      useNetw
        .put("api/service/ro/os/update", {
          svId: atob(this.svIdCode),
          roId: this.roShow,
          osId: this.os.osId,
          amount: this.os.amount,
          pricePerOne: this.os.pricePerOne,
          totalPrice: this.os.totalPrice,
          dcPer: this.os.dcPer,
          dcPrice: this.os.dcPrice,
          netPrice: this.os.netPrice,
          roWageTotalPrice: this.roWageTotalPrice,
          roWageDcPer: this.roWageDcPer,
          roWageDcPrice: this.roWageDcPrice,
          roWagePrice: this.roWagePrice,
          roPartTotalPrice: this.roPartTotalPrice,
          roPartDcPer: this.roPartDcPer,
          roPartDcPrice: this.roPartDcPrice,
          roPartNetPrice: this.roPartNetPrice,
          // os
          roOsTotalPrice: this.roOsTotalPrice,
          roOsDcPer: this.roOsDcPer,
          roOsDcPrice: this.roOsDcPrice,
          roOsNetPrice: this.roOsNetPrice,
          // liquid
          roLiquidTotalPrice: this.roLiquidTotalPrice,
          roLiquidDcPer: this.roLiquidDcPer,
          roLiquidDcPrice: this.roLiquidDcPrice,
          roLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          roExtraTotalPrice: this.roExtraTotalPrice,
          roExtraDcPer: this.roExtraDcPer,
          roExtraDcPrice: this.roExtraDcPrice,
          roExtraNetPrice: this.roExtraNetPrice,
          roTotalPrice: this.roTotalPrice,
          roDcPer: this.roDcPer,
          roDcPrice: this.roDcPrice,
          roNetPrice: this.roNetPrice,
          roVatPer: this.roVatPer,
          roVatPrice: this.roVatPrice,
          roGrandTotalPrice: this.roGrandTotalPrice,
          roWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          roWhtPrice: this.roWhtPrice,
          roPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.overlayFlag = false;
        });
    },
    updateRoExtra(
      extraId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice
    ) {
      this.extra.extraId = extraId;
      this.extra.pricePerOne = sellPrice;
      this.extra.amount = amount;
      this.extra.totalPrice = totalPrice;
      this.extra.dcPer = dcPer;
      this.extra.dcPrice = dcPrice;
      this.extra.netPrice = netPrice;
      // this.overlayFlag = true;

      useNetw
        .put("api/service/ro/extra/update", {
          svId: atob(this.svIdCode),
          roId: this.roShow,
          extraId: this.extra.extraId,
          amount: this.extra.amount,
          pricePerOne: this.extra.pricePerOne,
          totalPrice: this.extra.totalPrice,
          dcPer: this.extra.dcPer,
          dcPrice: this.extra.dcPrice,
          netPrice: this.extra.netPrice,
          roWageTotalPrice: this.roWageTotalPrice,
          roWageDcPer: this.roWageDcPer,
          roWageDcPrice: this.roWageDcPrice,
          roWagePrice: this.roWagePrice,
          roPartTotalPrice: this.roPartTotalPrice,
          roPartDcPer: this.roPartDcPer,
          roPartDcPrice: this.roPartDcPrice,
          roPartNetPrice: this.roPartNetPrice,
          // os
          roOsTotalPrice: this.roOsTotalPrice,
          roOsDcPer: this.roOsDcPer,
          roOsDcPrice: this.roOsDcPrice,
          roOsNetPrice: this.roOsNetPrice,
          // liquid
          roLiquidTotalPrice: this.roLiquidTotalPrice,
          roLiquidDcPer: this.roLiquidDcPer,
          roLiquidDcPrice: this.roLiquidDcPrice,
          roLiquidNetPrice: this.roLiquidNetPrice,
          // extra
          roExtraTotalPrice: this.roExtraTotalPrice,
          roExtraDcPer: this.roExtraDcPer,
          roExtraDcPrice: this.roExtraDcPrice,
          roExtraNetPrice: this.roExtraNetPrice,
          roTotalPrice: this.roTotalPrice,
          roDcPer: this.roDcPer,
          roDcPrice: this.roDcPrice,
          roNetPrice: this.roNetPrice,
          roVatPer: this.roVatPer,
          roVatPrice: this.roVatPrice,
          roGrandTotalPrice: this.roGrandTotalPrice,
          roWhtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          roWhtPrice: this.roWhtPrice,
          roPaymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // this.totalPart();
          this.calculateTotal();
          this.calculateTotalWage();
          this.calculateTotalPart();
          this.calculateTotalOs();
          this.calculateTotalLiquid();
          this.calculateTotalExtra();
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          // this.overlayFlag = false;
        });
    },
    updateRo: function () {
      this.calculateTotal();
      this.calculateTotalWage();
      this.calculateTotalPart();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalExtra();

      useNetw
        .put("api/service/ro/update", {
          svId: this.svShow,
          roId: this.roShow,
          roType: this.formdisUp.roTypeUp.id,
          mileNo: this.formdisUp.mileNoUp,
          roDate: this.formdisUp.roDateUp,
          isBooking: this.formdisUp.isBookingUp,
          isWash: this.formdisUp.isWashUp,
          isWaiting: this.formdisUp.isWaitingUp,
          note: this.formdisUp.noteUp,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          this.getRoWage(this.roShow);
          this.getRoPart(this.roShow);
          this.getRoLiquid(this.roShow);
          this.getRoOs(this.roShow);
          this.getRoExtra(this.roShow);
        });
    },
    updateRoPrice: function (svId, roId, roWhtPer) {
      this.svPriceId = svId;
      this.roPriceId = roId;
      this.roWhtPrice = roWhtPer;

      this.calculateTotal();
      this.calculateTotalWage();
      this.calculateTotalPart();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalExtra();

      useNetw
        .put("api/service/ro/update-price", {
          svId: this.svPriceId,
          roId: this.roPriceId,
          wageTotalPrice: this.roWageTotalPrice,
          wageDcPer: this.roWageDcPer,
          wageDcPrice: this.roWageDcPrice,
          wagePrice: this.roWagePrice,
          partTotalPrice: this.roPartTotalPrice,
          partDcPer: this.roPartDcPer,
          partDcPrice: this.roPartDcPrice,
          partNetPrice: this.roPartNetPrice,

          osTotalPrice: this.roOsTotalPrice,
          osDcPer: this.roOsDcPer,
          osDcPrice: this.roOsDcPrice,
          osNetPrice: this.roOsNetPrice,

          liquidTotalPrice: this.roLiquidTotalPrice,
          liquidDcPer: this.roLiquidDcPer,
          liquidDcPrice: this.roLiquidDcPrice,
          liquidNetPrice: this.roLiquidNetPrice,

          extraTotalPrice: this.roExtraTotalPrice,
          extraDcPer: this.roExtraDcPer,
          extraDcPrice: this.roExtraDcPrice,
          extraNetPrice: this.roExtraNetPrice,
          totalPrice: this.roTotalPrice,
          dcPer: this.roDcPer,
          dcPrice: this.roDcPrice,
          netPrice: this.roNetPrice,
          vatPer: this.roVatPer,
          vatPrice: this.roVatPrice,
          grandTotalPrice: this.roGrandTotalPrice,
          whtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          whtPrice: this.roWhtPrice != undefined ? this.roWhtPrice : 0,
          paymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roPriceId);
          this.getRoWage(this.roPriceId);
          this.getRoPart(this.roPriceId);
          this.getRoLiquid(this.roPriceId);
          this.getRoOs(this.roPriceId);
          this.getRoExtra(this.roPriceId);
        });
    },

    updateRoConfirm: function (svId, roId) {
      this.svPriceId = svId;
      this.roPriceId = roId;
      this.calculateTotal();
      this.calculateTotalWage();
      this.calculateTotalPart();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalExtra();

      useNetw
        .put("api/service/ro/update-price", {
          svId: this.svPriceId,
          roId: this.roPriceId,
          wageTotalPrice: this.roWageTotalPrice,
          wageDcPer: this.roWageDcPer,
          wageDcPrice: this.roWageDcPrice,
          wagePrice: this.roWagePrice,

          partTotalPrice: this.roPartTotalPrice,
          partDcPer: this.roPartDcPer,
          partDcPrice: this.roPartDcPrice,
          partNetPrice: this.roPartNetPrice,

          osTotalPrice: this.roOsTotalPrice,
          osDcPer: this.roOsDcPer,
          osDcPrice: this.roOsDcPrice,
          osNetPrice: this.roOsNetPrice,

          liquidTotalPrice: this.roLiquidTotalPrice,
          liquidDcPer: this.roLiquidDcPer,
          liquidDcPrice: this.roLiquidDcPrice,
          liquidNetPrice: this.roLiquidNetPrice,

          extraTotalPrice: this.roExtraTotalPrice,
          extraDcPer: this.roExtraDcPer,
          extraDcPrice: this.roExtraDcPrice,
          extraNetPrice: this.roExtraNetPrice,

          totalPrice: this.roTotalPrice,
          dcPer: this.roDcPer,
          dcPrice: this.roDcPrice,
          netPrice: this.roNetPrice,
          vatPer: this.roVatPer,
          vatPrice: this.roVatPrice,
          grandTotalPrice: this.roGrandTotalPrice,
          whtPer: this.roWhtPer.id != undefined ? this.roWhtPer.id : 0,
          whtPrice: this.roWhtPrice != undefined ? this.roWhtPrice : 0,
          paymentAmount: this.roPaymentAmount,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roPriceId);
          this.getRoWage(this.roPriceId);
          this.getRoPart(this.roPriceId);
          this.getRoLiquid(this.roPriceId);
          this.getRoOs(this.roPriceId);
          this.getRoExtra(this.roPriceId);
        });
    },
    deleteRoWage: function (rowRoWages, index) {
      this.rowRoWages.splice(index, 1);
      this.totalWage(rowRoWages);
      // this.calculateTotal(this.rowRoWages);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/service/ro/wage/delete", {
          data: {
            wageId: rowRoWages.wageId,
            svId: atob(this.svIdCode),
            roId: this.roShow,
            amount: this.wage.amount,
            pricePerOne: this.wage.pricePerOne,
            totalPrice: this.wage.totalPrice,
            dcPer: this.wage.dcPer,
            dcPrice: this.wage.dcPrice,
            netPrice: this.wage.netPrice,
            roWageTotalPrice: this.roWageTotalPrice,
            roWageDcPer: this.roWageDcPer,
            roWageDcPrice: this.roWageDcPrice,
            roWagePrice: this.roWagePrice,
            roPartTotalPrice: this.roPartTotalPrice,
            roPartDcPer: this.roPartDcPer,
            roPartDcPrice: this.roPartDcPrice,
            roPartNetPrice: this.roPartNetPrice,
            roOsTotalPrice: this.roOsTotalPrice,
            roOsDcPer: this.roOsDcPer,
            roOsDcPrice: this.roOsDcPrice,
            roOsNetPrice: this.roOsNetPrice,
            roLiquidTotalPrice: this.roLiquidTotalPrice,
            roLiquidDcPer: this.roLiquidDcPer,
            roLiquidDcPrice: this.roLiquidDcPrice,
            roLiquidNetPrice: this.roLiquidNetPrice,
            roExtraTotalPrice: this.roExtraTotalPrice,
            roExtraDcPer: this.roExtraDcPer,
            roExtraDcPrice: this.roExtraDcPrice,
            roExtraNetPrice: this.roExtraNetPrice,
            roTotalPrice: this.roTotalPrice,
            roDcPer: this.roDcPer,
            roDcPrice: this.roDcPrice,
            roNetPrice: this.roNetPrice,
            roVatPer: this.roVatPer,
            roVatPrice: this.roVatPrice,
            roGrandTotalPrice: this.roGrandTotalPrice,
            roWhtPer: this.roWhtPer.name,
            roWhtPrice: this.roWhtPrice,
            roPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getRoShow(this.roShow);
          this.getRoWage(this.roShow);
          this.loading = false; //skeleton false
        });
    },
    deleteRoPart: function (rowRoParts, index) {
      this.rowRoParts.splice(index, 1);
      this.totalPart(rowRoParts);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/service/ro/part/delete", {
          data: {
            partId: rowRoParts.partId,
            svId: atob(this.svIdCode),
            roId: this.roShow,
            amount: this.part.amount,
            pricePerOne: this.part.pricePerOne,
            totalPrice: this.part.totalPrice,
            dcPer: this.part.dcPer,
            dcPrice: this.part.dcPrice,
            netPrice: this.part.netPrice,
            roWageTotalPrice: this.roWageTotalPrice,
            roWageDcPer: this.roWageDcPer,
            roWageDcPrice: this.roWageDcPrice,
            roWagePrice: this.roWagePrice,
            roPartTotalPrice: this.roPartTotalPrice,
            roPartDcPer: this.roPartDcPer,
            roPartDcPrice: this.roPartDcPrice,
            roPartNetPrice: this.roPartNetPrice,
            roOsTotalPrice: this.roOsTotalPrice,
            roOsDcPer: this.roOsDcPer,
            roOsDcPrice: this.roOsDcPrice,
            roOsNetPrice: this.roOsNetPrice,
            roLiquidTotalPrice: this.roLiquidTotalPrice,
            roLiquidDcPer: this.roLiquidDcPer,
            roLiquidDcPrice: this.roLiquidDcPrice,
            roLiquidNetPrice: this.roLiquidNetPrice,
            roExtraTotalPrice: this.roExtraTotalPrice,
            roExtraDcPer: this.roExtraDcPer,
            roExtraDcPrice: this.roExtraDcPrice,
            roExtraNetPrice: this.roExtraNetPrice,
            roTotalPrice: this.roTotalPrice,
            roDcPer: this.roDcPer,
            roDcPrice: this.roDcPrice,
            roNetPrice: this.roNetPrice,
            roVatPer: this.roVatPer,
            roVatPrice: this.roVatPrice,
            roGrandTotalPrice: this.roGrandTotalPrice,
            roWhtPer: this.roWhtPer.name,
            roWhtPrice: this.roWhtPrice,
            roPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getRoShow(this.roShow);
          this.getRoPart(this.roShow);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoLiquid: function (rowRoLiquid, index) {
      this.rowRoLiquid.splice(index, 1);
      this.totalLiquid(rowRoLiquid);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/service/ro/liquid/delete", {
          data: {
            partId: rowRoLiquid.partId,
            svId: atob(this.svIdCode),
            roId: this.roShow,
            amount: this.liquid.amount,
            pricePerOne: this.liquid.pricePerOne,
            totalPrice: this.liquid.totalPrice,
            dcPer: this.liquid.dcPer,
            dcPrice: this.liquid.dcPrice,
            netPrice: this.liquid.netPrice,
            roWageTotalPrice: this.roWageTotalPrice,
            roWageDcPer: this.roWageDcPer,
            roWageDcPrice: this.roWageDcPrice,
            roWagePrice: this.roWagePrice,
            roPartTotalPrice: this.roPartTotalPrice,
            roPartDcPer: this.roPartDcPer,
            roPartDcPrice: this.roPartDcPrice,
            roPartNetPrice: this.roPartNetPrice,
            roOsTotalPrice: this.roOsTotalPrice,
            roOsDcPer: this.roOsDcPer,
            roOsDcPrice: this.roOsDcPrice,
            roOsNetPrice: this.roOsNetPrice,
            roLiquidTotalPrice: this.roLiquidTotalPrice,
            roLiquidDcPer: this.roLiquidDcPer,
            roLiquidDcPrice: this.roLiquidDcPrice,
            roLiquidNetPrice: this.roLiquidNetPrice,
            roExtraTotalPrice: this.roExtraTotalPrice,
            roExtraDcPer: this.roExtraDcPer,
            roExtraDcPrice: this.roExtraDcPrice,
            roExtraNetPrice: this.roExtraNetPrice,
            roTotalPrice: this.roTotalPrice,
            roDcPer: this.roDcPer,
            roDcPrice: this.roDcPrice,
            roNetPrice: this.roNetPrice,
            roVatPer: this.roVatPer,
            roVatPrice: this.roVatPrice,
            roGrandTotalPrice: this.roGrandTotalPrice,
            roWhtPer: this.roWhtPer.name,
            roWhtPrice: this.roWhtPrice,
            roPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getRoShow(this.roShow);
          this.getRoLiquid(this.roShow);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoOs: function (rowRoOs, index) {
      this.rowRoOs.splice(index, 1);
      this.totalOs(rowRoOs);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/service/ro/os/delete", {
          data: {
            osId: rowRoOs.osId,
            svId: atob(this.svIdCode),
            roId: this.roShow,
            amount: this.os.amount,
            pricePerOne: this.os.pricePerOne,
            totalPrice: this.os.totalPrice,
            dcPer: this.os.dcPer,
            dcPrice: this.os.dcPrice,
            netPrice: this.os.netPrice,
            roWageTotalPrice: this.roWageTotalPrice,
            roWageDcPer: this.roWageDcPer,
            roWageDcPrice: this.roWageDcPrice,
            roWagePrice: this.roWagePrice,
            roPartTotalPrice: this.roPartTotalPrice,
            roPartDcPer: this.roPartDcPer,
            roPartDcPrice: this.roPartDcPrice,
            roPartNetPrice: this.roPartNetPrice,
            roOsTotalPrice: this.roOsTotalPrice,
            roOsDcPer: this.roOsDcPer,
            roOsDcPrice: this.roOsDcPrice,
            roOsNetPrice: this.roOsNetPrice,
            roLiquidTotalPrice: this.roLiquidTotalPrice,
            roLiquidDcPer: this.roLiquidDcPer,
            roLiquidDcPrice: this.roLiquidDcPrice,
            roLiquidNetPrice: this.roLiquidNetPrice,
            roExtraTotalPrice: this.roExtraTotalPrice,
            roExtraDcPer: this.roExtraDcPer,
            roExtraDcPrice: this.roExtraDcPrice,
            roExtraNetPrice: this.roExtraNetPrice,
            roTotalPrice: this.roTotalPrice,
            roDcPer: this.roDcPer,
            roDcPrice: this.roDcPrice,
            roNetPrice: this.roNetPrice,
            roVatPer: this.roVatPer,
            roVatPrice: this.roVatPrice,
            roGrandTotalPrice: this.roGrandTotalPrice,
            roWhtPer: this.roWhtPer.name,
            roWhtPrice: this.roWhtPrice,
            roPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getRoShow(this.roShow);
          this.getRoOs(this.roShow);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    deleteRoExtra: function (rowRoExtra, index) {
      this.rowRoExtra.splice(index, 1);
      this.totalExtra(rowRoExtra);
      // this.calculateTotal(this.rowRoExtra);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/service/ro/extra/delete", {
          data: {
            extraId: rowRoExtra.extraId,
            svId: atob(this.svIdCode),
            roId: this.roShow,
            amount: this.os.amount,
            pricePerOne: this.os.pricePerOne,
            totalPrice: this.os.totalPrice,
            dcPer: this.os.dcPer,
            dcPrice: this.os.dcPrice,
            netPrice: this.os.netPrice,
            roWageTotalPrice: this.roWageTotalPrice,
            roWageDcPer: this.roWageDcPer,
            roWageDcPrice: this.roWageDcPrice,
            roWagePrice: this.roWagePrice,
            roPartTotalPrice: this.roPartTotalPrice,
            roPartDcPer: this.roPartDcPer,
            roPartDcPrice: this.roPartDcPrice,
            roPartNetPrice: this.roPartNetPrice,
            roOsTotalPrice: this.roOsTotalPrice,
            roOsDcPer: this.roOsDcPer,
            roOsDcPrice: this.roOsDcPrice,
            roOsNetPrice: this.roOsNetPrice,
            roLiquidTotalPrice: this.roLiquidTotalPrice,
            roLiquidDcPer: this.roLiquidDcPer,
            roLiquidDcPrice: this.roLiquidDcPrice,
            roLiquidNetPrice: this.roLiquidNetPrice,
            roExtraTotalPrice: this.roExtraTotalPrice,
            roExtraDcPer: this.roExtraDcPer,
            roExtraDcPrice: this.roExtraDcPrice,
            roExtraNetPrice: this.roExtraNetPrice,
            roTotalPrice: this.roTotalPrice,
            roDcPer: this.roDcPer,
            roDcPrice: this.roDcPrice,
            roNetPrice: this.roNetPrice,
            roVatPer: this.roVatPer,
            roVatPrice: this.roVatPrice,
            roGrandTotalPrice: this.roGrandTotalPrice,
            roWhtPer: this.roWhtPer.name,
            roWhtPrice: this.roWhtPrice,
            roPaymentAmount: this.roPaymentAmount,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getRoShow(this.roShow);
          this.getRoExtra(this.roShow);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },

    upsertWage(selectWage, wage, roId) {
      this.upRoId = roId;

      if (wage.wageId.length === 0) {
        wage.wageId = selectWage.wageId;
        wage.amount = 1;
        wage.pricePerOne = selectWage.price ? selectWage.price : 0;
        wage.totalPrice = selectWage.price ? selectWage.price : 0;
        wage.dcPer = 0;
        wage.dcPrice = 0;
        wage.netPrice = selectWage.price;
        wage.selectWage = selectWage;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.totalWage(wage);
        this.calculateTotalWage();
        this.calculateTotal();
        this.calculateTotalPart();
        this.postRoWage(wage, wage.pricePerOne, wage.wageId);
      } else {
        //ถ้ามีใบสั่งซ่อมซ้ำให้ทำการอัพเดต
        wage.wageId = selectWage.wageId;
        wage.amount = 1;
        wage.pricePerOne = selectWage.price ? selectWage.price : 0;
        wage.totalPrice = selectWage.price ? selectWage.price : 0;
        wage.dcPer = 0;
        wage.dcPrice = 0;
        wage.netPrice = selectWage.price;
        wage.selectWage = selectWage;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.totalWage(wage);
        this.calculateTotalWage();
        this.calculateTotal();
        this.calculateTotalPart();
        this.updateRoWage(
          wage.wageId,
          wage.pricePerOne,
          wage.amount,
          wage.totalPrice,
          wage.dcPer,
          wage.dcPrice,
          wage.netPrice
        ); //, wage.pricePerOne
      }
    },

    upsertPart(selectPart, part) {
      if (part.partId.length === 0) {
        part.partId = selectPart.partId;
        part.amount = 1;
        part.pricePerOne = selectPart.sellPrice ? selectPart.sellPrice : 0;
        part.totalPrice = selectPart.sellPrice ? selectPart.sellPrice : 0;
        part.dcPer = 0;
        part.dcPrice = 0;
        part.netPrice = selectPart.sellPrice;
        part.selectPart = selectPart;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalPart(part);
        this.calculateTotalPart();
        this.postRoPart(part, part.pricePerOne);
      } else {
        part.partId = selectPart.partId;
        part.amount = 1;
        part.pricePerOne = selectPart.sellPrice ? selectPart.sellPrice : 0;
        part.totalPrice = selectPart.sellPrice ? selectPart.sellPrice : 0;
        part.dcPer = 0;
        part.dcPrice = 0;
        part.netPrice = selectPart.sellPrice;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalPart(part);
        this.calculateTotalPart();
        this.updateRoPart(
          part.partId,
          part.pricePerOne,
          part.amount,
          part.totalPrice,
          part.dcPer,
          part.dcPrice,
          part.netPrice
        );
      }
    },
    upsertLiquid(selectLiquid, liquid) {
      if (liquid.partId.length === 0) {
        liquid.partId = selectLiquid.partId;
        liquid.amount = 1;
        liquid.pricePerOne =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.totalPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.dcPer = 0;
        liquid.dcPrice = 0;
        liquid.netPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.selectLiquid = selectLiquid;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalLiquid(liquid);
        this.calculateTotalPart();
        this.postRoLiquid(liquid, liquid.pricePerOne);
      } else {
        liquid.partId = selectLiquid.partId;
        liquid.amount = 1;
        liquid.pricePerOne =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.totalPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        liquid.dcPer = 0;
        liquid.dcPrice = 0;
        liquid.netPrice =
          selectLiquid.sellPrice != null ? selectLiquid.sellPrice : 0;
        this.calculateTotalExtra();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalWage();
        this.calculateTotal();
        this.totalLiquid(liquid);
        this.calculateTotalPart();
        this.updateRoLiquid(
          liquid.partId,
          liquid.pricePerOne,
          liquid.amount,
          liquid.totalPrice,
          liquid.dcPer,
          liquid.dcPrice,
          liquid.netPrice
        );
      }
    },
    upsertOs(selectOs, os) {
      if (os.osId.length === 0) {
        os.osId = selectOs.osId;
        os.amount = 1;
        os.pricePerOne = selectOs.price != null ? selectOs.price : 0;
        os.totalPrice = selectOs.price != null ? selectOs.price : 0;
        os.dcPer = 0;
        os.dcPrice = 0;
        os.netPrice = selectOs.price != null ? selectOs.price : 0;
        os.selectOs = selectOs;

        this.totalOs(os);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.postRoOs(os, os.pricePerOne);
      } else {
        os.osId = selectOs.osId;
        os.amount = 1;
        os.pricePerOne = selectOs.price != null ? selectOs.price : 0;
        os.totalPrice = selectOs.price != null ? selectOs.price : 0;
        os.dcPer = 0;
        os.dcPrice = 0;
        os.netPrice = selectOs.price != null ? selectOs.price : 0;

        this.totalOs(os);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.updateRoOs(
          os.osId,
          os.pricePerOne,
          os.amount,
          os.totalPrice,
          os.dcPer,
          os.dcPrice,
          os.netPrice
        );
      }
    },
    upsertExtra(selectExtra, extra) {
      if (extra.extraId.length === 0) {
        extra.extraId = selectExtra.extraId;
        extra.amount = 1;
        extra.pricePerOne = selectExtra.price != null ? selectExtra.price : 0;
        extra.totalPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.dcPer = 0;
        extra.dcPrice = 0;
        extra.netPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.selectOs = selectExtra;

        this.totalExtra(extra);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.postRoExtra(extra, extra.pricePerOne);
      } else {
        extra.extraId = selectExtra.extraId;
        extra.amount = 1;
        extra.pricePerOne = selectExtra.price != null ? selectExtra.price : 0;
        extra.totalPrice = selectExtra.price != null ? selectExtra.price : 0;
        extra.dcPer = 0;
        extra.dcPrice = 0;
        extra.netPrice = selectExtra.price != null ? selectExtra.price : 0;

        this.totalExtra(extra);
        this.calculateTotal();
        this.calculateTotalWage();
        this.calculateTotalPart();
        this.calculateTotalOs();
        this.calculateTotalLiquid();
        this.calculateTotalExtra();
        this.updateRoExtra(
          extra.extraId,
          extra.pricePerOne,
          extra.amount,
          extra.totalPrice,
          extra.dcPer,
          extra.dcPrice,
          extra.netPrice
        );
      }
    },

    //######แก้ไขส่วนของอะไหล่ ทำแก้ไข  แก้ตัวแปร
    totalWage(wage) {
      let totalPrices = parseFloat(wage.pricePerOne) * parseFloat(wage.amount); //มูลค่า
      if (!isNaN(totalPrices)) {
        //ไม่ให้เป็น NaN
        wage.totalPrice = totalPrices.toFixed(2);
      }

      // =========ส่วนลด
      var dcPrice = 0;
      if (this.wage.per) {
        dcPrice = parseFloat(wage.totalPrice) * parseFloat(wage.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          wage.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.wage.bath) {
        wage.dcPrice = wage.dcPer;
      } else {
        wage.dcPrice = wage.dcPer;
      }

      let netPrice = parseFloat(wage.totalPrice) - parseFloat(wage.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        wage.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalPart(part) {
      let totalPrice = parseFloat(part.pricePerOne) * parseFloat(part.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        part.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.part.per) {
        dcPrice = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          part.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.part.bath) {
        part.dcPrice = part.dcPer;
      } else {
        part.dcPrice = part.dcPer;
      }

      let netPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        part.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalLiquid(liquid) {
      let totalPrice =
        parseFloat(liquid.pricePerOne) * parseFloat(liquid.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        liquid.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.liquid.per) {
        dcPrice =
          parseFloat(liquid.totalPrice) * parseFloat(liquid.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          liquid.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.liquid.bath) {
        liquid.dcPrice = liquid.dcPer;
      } else {
        liquid.dcPrice = liquid.dcPer;
      }

      let netPrice = parseFloat(liquid.totalPrice) - parseFloat(liquid.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        liquid.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalOs(os) {
      let totalPrice = parseFloat(os.pricePerOne) * parseFloat(os.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        os.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.os.per) {
        dcPrice = parseFloat(os.totalPrice) * parseFloat(os.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          os.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.os.bath) {
        os.dcPrice = os.dcPer;
      } else {
        os.dcPrice = os.dcPer;
      }

      let netPrice = parseFloat(os.totalPrice) - parseFloat(os.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        os.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },
    totalExtra(extra) {
      let totalPrice = parseFloat(extra.pricePerOne) * parseFloat(extra.amount); //มูลค่า
      if (!isNaN(totalPrice)) {
        //ไม่ให้เป็น NaN
        extra.totalPrice = totalPrice.toFixed(2);
      }

      var dcPrice = 0;
      if (this.extra.per) {
        dcPrice = parseFloat(extra.totalPrice) * parseFloat(extra.dcPer / 100); //ส่วนลด
        if (!isNaN(dcPrice)) {
          //ไม่ให้เป็น NaN
          extra.dcPrice = dcPrice.toFixed(2);
        }
      } else if (this.extra.bath) {
        extra.dcPrice = extra.dcPer;
      } else {
        extra.dcPrice = extra.dcPer;
      }

      let netPrice = parseFloat(extra.totalPrice) - parseFloat(extra.dcPrice); //สุทธิ(ลดแล้ว)
      if (!isNaN(netPrice)) {
        //ไม่ให้เป็น NaN
        extra.netPrice = netPrice.toFixed(2);
      }
      this.calculateTotalExtra();
      this.calculateTotalOs();
      this.calculateTotalLiquid();
      this.calculateTotalPart();
      this.calculateTotalWage();
      this.calculateTotal();
    },

    calculateTotalWage() {
      var totalNetPriceCal, roWageTotalPriceCal, roWageDcPriceCal;
      totalNetPriceCal = this.rowRoWages.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.roWagePrice = totalNetPriceCal.toFixed(2); //ค่าแรงสุทธิ

      roWageTotalPriceCal = this.rowRoWages.reduce(function (sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roWageTotalPrice = roWageTotalPriceCal.toFixed(2); //รวมค่าแรง

      if (this.wage.dcMethod.per === "per") {
        let roWageDcPerCal = this.rowRoWages.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);
        this.roWageDcPer = roWageDcPerCal.toFixed(2); //รวมส่วนลด บาท
      } else if (this.wage.dcMethod.bath === "bath") {
        let roWageDcPerCal = this.rowRoWages.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);
        this.roWageDcPer = roWageDcPerCal.toFixed(2); //รวมส่วนลด บาท
      }

      roWageDcPriceCal = this.rowRoWages.reduce(function (sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roWageDcPrice = roWageDcPriceCal.toFixed(2); //รวมส่วนลด บาท
    },

    calculateTotalPart() {
      var roPartNetPriceCal, roPartTotalPriceCal, roPartDcPriceCal;
      roPartNetPriceCal = this.rowRoParts.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roPartNetPrice = roPartNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roPartTotalPriceCal = this.rowRoParts.reduce(function (sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roPartTotalPrice = roPartTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roPartDcPriceCal = this.rowRoParts.reduce(function (sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roPartDcPrice = roPartDcPriceCal.toFixed(2); //รวมส่วนลด บาท #####

      if (this.wage.dcMethod.per === "per") {
        let roPartDcPerCal = this.rowRoParts.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roPartDcPer = roPartDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.part.dcMethod.bath === "bath") {
        let roPartDcPerCal = this.rowRoParts.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roPartDcPer = roPartDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalLiquid() {
      var roLiquidNetPriceCal, roLiquidTotalPriceCal, roLiquidDcPriceCal;
      roLiquidNetPriceCal = this.rowRoLiquid.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roLiquidNetPrice = roLiquidNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roLiquidTotalPriceCal = this.rowRoLiquid.reduce(function (sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roLiquidTotalPrice = roLiquidTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roLiquidDcPriceCal = this.rowRoLiquid.reduce(function (sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roLiquidDcPrice = roLiquidDcPriceCal; //รวมส่วนลด บาท #####

      if (this.liquid.dcMethod.per === "per") {
        let roLiquidDcPerCal = this.rowRoLiquid.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roLiquidDcPer = roLiquidDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.liquid.dcMethod.bath === "bath") {
        let roLiquidDcPerCal = this.rowRoLiquid.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roLiquidDcPer = roLiquidDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalOs() {
      var roOsNetPriceCal, roOsTotalPriceCal, roOsDcPriceCal;
      roOsNetPriceCal = this.rowRoOs.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roOsNetPrice = roOsNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roOsTotalPriceCal = this.rowRoOs.reduce(function (sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roOsTotalPrice = roOsTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roOsDcPriceCal = this.rowRoOs.reduce(function (sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roOsDcPrice = roOsDcPriceCal; //รวมส่วนลด บาท #####

      if (this.os.dcMethod.per === "per") {
        let roOsDcPerCal = this.rowRoOs.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roOsDcPer = roOsDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.os.dcMethod.bath === "bath") {
        let roOsDcPerCal = this.rowRoOs.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roOsDcPer = roOsDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotalExtra() {
      var roExtraNetPriceCal, roExtraTotalPriceCal, roExtraDcPriceCal;
      roExtraNetPriceCal = this.rowRoExtra.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.roExtraNetPrice = roExtraNetPriceCal.toFixed(2); //ค่าแรงสุทธิ #####

      roExtraTotalPriceCal = this.rowRoExtra.reduce(function (sum, item) {
        //รวมค่าแรง
        var totalPrice = parseFloat(item.totalPrice);
        if (!isNaN(totalPrice)) {
          return sum + totalPrice;
        }
      }, 0);

      this.roExtraTotalPrice = roExtraTotalPriceCal.toFixed(2); //รวมค่าแรง ####

      roExtraDcPriceCal = this.rowRoExtra.reduce(function (sum, item) {
        //รวมส่วนลด บาท
        var dcPrice = parseFloat(item.dcPrice);
        if (!isNaN(dcPrice)) {
          return sum + dcPrice;
        }
      }, 0);

      this.roExtraDcPrice = roExtraDcPriceCal; //รวมส่วนลด บาท #####

      if (this.extra.dcMethod.per === "per") {
        let roExtraDcPerCal = this.rowRoExtra.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roExtraDcPer = roExtraDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      } else if (this.extra.dcMethod.bath === "bath") {
        let roExtraDcPerCal = this.rowRoExtra.reduce(function (sum, item) {
          //รวมส่วนลด บาท
          var dcPer = parseFloat(item.dcPer);
          if (!isNaN(dcPer)) {
            return sum + dcPer;
          }
        }, 0);

        this.roExtraDcPer = roExtraDcPerCal.toFixed(2); //รวมส่วนลด บาท #####
      }
    },
    calculateTotal() {
      let roTotalPrice =
        parseFloat(this.roPartNetPrice) +
        parseFloat(this.roWagePrice) +
        parseFloat(this.roLiquidNetPrice) +
        parseFloat(this.roOsNetPrice) +
        parseFloat(this.roExtraNetPrice);

      if (!isNaN(roTotalPrice)) {
        //ไม่ให้เป็น NaN
        this.roTotalPrice = roTotalPrice.toFixed(2); //รวมอะไหล่ ค่าแรง%#####
      }

      let roDcPrice =
        parseFloat(this.roTotalPrice) * parseFloat(this.roDcPer / 100);
      if (!isNaN(roDcPrice)) {
        //ไม่ให้เป็น NaN
        this.roDcPrice = roDcPrice.toFixed(2); //ส่วนลด%#####
      }

      let roNetPrice = parseFloat(this.roTotalPrice - this.roDcPrice);
      if (!isNaN(roNetPrice)) {
        this.roNetPrice = roNetPrice.toFixed(2); //สุทธิ ####
      }

      let roVatPrice = this.roNetPrice * parseFloat(this.roVatPer / 100);
      if (!isNaN(roVatPrice)) {
        this.roVatPrice = roVatPrice.toFixed(2); //ภาาี ####
      }

      let roGrandTotalPrice =
        parseFloat(this.roNetPrice) + parseFloat(this.roVatPrice);
      if (!isNaN(roGrandTotalPrice)) {
        this.roGrandTotalPrice = roGrandTotalPrice.toFixed(2); //สุทธิ + ภาษี ####
      }

      let roWhtPrice = this.roNetPrice * (this.roWhtPer.id / 100);
      if (!isNaN(roWhtPrice)) {
        this.roWhtPrice = roWhtPrice.toFixed(2); //สุทธิ + ภาษี ####
      }

      let roPaymentAmount =
        parseFloat(this.roGrandTotalPrice) - parseFloat(this.roWhtPrice);
      if (!isNaN(roPaymentAmount)) {
        this.roPaymentAmount = roPaymentAmount.toFixed(2); //จำนวนเงินที่ต้องจ่าย ####
      }
    },

    alertWage(rowRoWages, index) {
      Swal.fire({
        title: "ต้องการลบค่าแรงใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoWage(rowRoWages, index);
        }
      });
    },
    alertPart(rowRoParts, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบค่าอะไหล่ใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoPart(rowRoParts, index);
        }
      });
    },
    alertOs(rowRoOs, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoOs(rowRoOs, index);
        }
      });
    },
    alertLiquid(rowRoLiquid, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoLiquid(rowRoLiquid, index);
        }
      });
    },
    alertExtra(rowRoExtra, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "ต้องการลบใช่หรือไม่",
        icon: "warning",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteRoExtra(rowRoExtra, index);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 80px;
  text-align: right;
}
.price {
  width: 100px;
  text-align: right;
}
td {
  padding-left: 0.5px;
  padding-right: 0.5px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
