import { render, staticRenderFns } from "./tabSvDeliveryComponent.vue?vue&type=template&id=7438f6a0"
import script from "./tabSvDeliveryComponent.vue?vue&type=script&lang=js"
export * from "./tabSvDeliveryComponent.vue?vue&type=script&lang=js"
import style0 from "./tabSvDeliveryComponent.vue?vue&type=style&index=0&id=7438f6a0&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports