<template>
  <b-tab>
    <template v-slot:title>
      <i class="uil uil-car-sideview font-size-20"></i>
      <span class="d-none d-sm-block">อัลบั้มภาพ</span>
    </template>

    <div class="card mt-4">
      <div class="card-body shadow">
        <h4 class="card-title">
          ภาพประกอบ
          <span class="badge bg-danger">{{ rowsImg.length }}</span>
        </h4>
        <hr />
        <vue-dropzone
          id="imgFile"
          ref="imgFile"
          :use-custom-slot="true"
          :options="dropzoneOptions"
          v-on:vdropzone-success="getDataloadImage"
          v-on:vdropzone-sending="sendingEvent"
        >
          <div class="dropzone-custom-content">
            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
            <h4>อัพโหลดไฟล์ภาพ</h4>
          </div>
        </vue-dropzone>

        <div class="popup-gallery">
          <div
            v-for="(data, index) in rowsImg"
            :key="'img' + index"
            class="float-start m-2"
            @click="() => showImg(index)"
          >
            <div class="card shadow">
              <img :src="data.path" class="d-block" width="180" height="180" />
              <span class="badge bg-success">{{data.userName}}</span>
            </div>
          </div>
          <vue-easy-lightbox
            :visible="visible"
            :imgs="this.allImg"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
        </div>
      </div>
    </div>
  </b-tab>
</template>

<script>
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "tabAlbumComponent",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      svId: this.$route.params.id,
      svIdCre: this.$route.params.svId,
      svIdDecode: this.$route.params.id,
      svIdCreDecode: this.$route.params.svId,
      rowsImg: [],
      allImg: "",
      visible: false,
      index: 0,
      dropzoneOptions: {
        url: `${appConfig.api.baseURL}api/service/image/upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        uploadMultiple: true,
        paramName: "imgFile",
        init: function () {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // console.log(formData);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
    };
  },
  created() {
    this.getDataImage();
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      if (this.svIdCre != null) {
        formData.append("svId", this.svIdCreDecode);
      }
      if (this.svId != null) {
        formData.append("svId", this.svIdDecode);
      }
    },
    getDataImage: function () {
      this.overlayFlag = true;
      if (this.svIdCre != null) {
        const decodedString = atob(this.svIdCreDecode);
        this.svIdCreDecode = decodedString;
        useNetw
          .get("api/service/images", {
            params: {
              svId: this.svIdCreDecode,
            },
          })
          .then((response) => {
            this.rowsImg = response.data.images;

            const arr = [];
            this.rowsImg.forEach((item) => {
              arr.push(item.path);
            });
            this.allImg = arr;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
      if (this.svId != null) {
        const decodedString = atob(this.svIdDecode);
        this.svIdDecode = decodedString;
        useNetw
          .get("api/service/images", {
            params: {
              svId: this.svIdDecode,
            },
          })
          .then((response) => {
            this.rowsImg = response.data.images;
            //   response.data.images.forEach((item) => {
            //     this.allImg.push(item.path);
            //   });
            //const arr = this.rowsImg.filter(item => item !== response.data.images.fileId)
            //this.allImg = arr
            const arr = [];
            this.rowsImg.forEach((item) => {
              arr.push(item.path);
            });
            this.allImg = arr;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    getDataloadImage: function () {
      this.overlayFlag = true;
      if (this.svIdCre != null) {
        useNetw
          .get("api/service/images", {
            params: {
              svId: this.svIdCreDecode,
            },
          })
          .then((response) => {
            this.rowsImg = response.data.images;

            const arr = [];
            this.rowsImg.forEach((item) => {
              arr.push(item.path);
            });
            this.allImg = arr;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
      if (this.svId != null) {
        useNetw
          .get("api/service/images", {
            params: {
              svId: this.svIdDecode,
            },
          })
          .then((response) => {
            this.rowsImg = response.data.images;
            //   response.data.images.forEach((item) => {
            //     this.allImg.push(item.path);
            //   });
            //const arr = this.rowsImg.filter(item => item !== response.data.images.fileId)
            //this.allImg = arr
            const arr = [];
            this.rowsImg.forEach((item) => {
              arr.push(item.path);
            });
            this.allImg = arr;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style></style>
