<script>
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "tabComponent",
  props: {
    inspId: {
      required: true,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      imgruleId: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      svId: this.$route.params.svId,
      svIdDecode: this.$route.params.svId,
      resalert: "",
      token: "",
      overlayFlag: false,
      inspectionDetailData: [],
      inspection: [],
      extraValue: "",
      rowsImg: [],
      index: 0,
      visible: false,
      allImg: "",
      titleParts: "",

      options: [
        { text: " ครบ", value: "0" },
        { text: " ไม่ครบ", value: "1" },
      ],
      optionsClean: [
        { text: " ล้าง", value: "0" },
        { text: " ไม่ล้าง", value: "1" },
      ],

      sliderWithLabel: {
        value: 1,
        data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        range: [
          {
            label: "1",
          },
          {
            label: "2",
            isHide: true,
          },
          {
            label: "3",
          },
          {
            label: "3",
            isHide: true,
          },
          {
            label: "5",
          },
          {
            label: "6",
            isHide: true,
          },
          {
            label: "7",
          },
          {
            label: "8",
            isHide: true,
          },
          {
            label: "9",
          },
          {
            label: "10",
            isHide: true,
          },
        ],
        rangeValue: {},
      },

      dropzoneOptions: {
        url:
          `${appConfig.api.baseURL}api/service/inspection/image/upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        uploadMultiple: true,
        acceptedFiles: ".jpg, .jpeg, .png",
        // dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
        //   <p class="form-text">Allowed Files: .jpg, .jpeg, .png</p>
        //   `,
        paramName: "imgFile",
        init: function() {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
    };
  },
  created() {
    this.getInspectionDetail();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    // printArray: function(g, r, v) {
    // },
    getInspectionDetail: function() {
      const decodedString = atob(this.svIdDecode);
      this.svIdDecode = decodedString;
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection/show", {
          params: {
            svId: this.svIdDecode,
            inspId: this.inspId,
          },
        })
        .then((response) => {
          this.inspection = response.data.inspection;
          this.inspection.inspId = response.data.inspection.inspId;
          this.inspectionDetailData = response.data.inspectionDetailData;
          // this.extraValue = response.data.inspectionDetailData;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getInspectionUpimgSuc: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection/show", {
          params: {
            svId: this.svIdDecode,
            inspId: this.inspId,
          },
        })
        .then((response) => {
          this.inspection = response.data.inspection;
          this.inspectionDetailData = response.data.inspectionDetailData;
          // this.extraValue = response.data.inspectionDetailData;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateSelectChoice(inspId, ruleId, value) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: this.svIdDecode,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: JSON.stringify(value),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateExtraValue(inspId, ruleId, extravalue) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: this.svIdDecode,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: extravalue,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateNote(inspId, ruleId, note) {
      useNetw
        .put("/api/service/inspection/update-choice-note", {
          svId: this.svIdDecode,
          inspId: inspId,
          ruleId: ruleId,
          note: note,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    updateSelected(inspId, ruleId, selectChoice) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: this.svIdDecode,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: selectChoice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateExtraFuel(inspId, ruleId, fuel) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: this.svIdDecode,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: fuel,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getInspectionUpimgSuc();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataImage: function(inspId, ruleId) {
      this.overlayFlag = true;
      this.inspId = inspId;
      this.ruleId = ruleId;
      useNetw
        .get("api/service/inspection/images", {
          params: {
            svId: this.svIdDecode,
            inspId: inspId,
            ruleId: ruleId,
          },
        })
        .then((response) => {
          this.rowsImg = response.data.images;

          const arrImg = [];
          this.rowsImg.forEach((item) => {
            arrImg.push(item.path);
          });
          this.allImg = arrImg;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataImageUpseccess: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection/images", {
          params: {
            svId: this.svIdDecode,
            inspId: this.inspId,
            ruleId: this.ruleId,
          },
        })
        .then((response) => {
          this.rowsImg = response.data.images;

          const arrImg = [];
          this.rowsImg.forEach((item) => {
            arrImg.push(item.path);
          });
          this.allImg = arrImg;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.getInspectionUpimgSuc();
        });
    },
    sendingEvent(file, xhr, formData) {
      formData.append("svId", this.svIdDecode);
      formData.append("inspId", this.inspId);
      formData.append("ruleId", this.ruleId);
    },
    print: function(inspId) {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection/print", {
          params: {
            svId:
              this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
            inspId: inspId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // sendingParts(file, xhr, formData) {
    //   formData.append("svId", this.svId);
    //   formData.append("inspId", this.inspId);
    //   formData.append("ruleId", this.ruleId);
    // },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
    test(ruleId, message, inspId) {
      this.imgruleId = ruleId;
      this.titleParts = message;
      this.inspId = inspId;
      this.getDataImage(inspId, ruleId);

      // this.showAlert(ruleId + message);
      // alert(message);
      // document.getElementById("tire-top-right").click();
    },
    sendNote(textnote) {
      useNetw
        .put("/api/service/inspection/update-choice-note", {
          svId: this.svIdDecode,
          inspId: this.inspId,
          ruleId: this.ruleId,
          note: textnote,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
  middleware: "authentication",
};
</script>

<style>
.parts {
  height: 30px;
  width: 30px;
  cursor: pointer;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
  background-color: #d00217;
}

/* กันชนหน้า */
.parts[data-id="tire-bt-24"] {
  top: 6rem;
  left: 45rem;
}

/* ฝากระโปรงหน้า */
.parts[data-id="tire-bt-25"] {
  top: 6rem;
  left: 36.5rem;
}

/* บังโคลนหน้าขวา */
.parts[data-id="tire-bt-26"] {
  top: 14rem;
  left: 39rem;
}

/* บังโคลนหน้าซ้าย */
.parts[data-id="tire-bt-27"] {
  top: 2rem;
  left: 39.7rem;
}

/* ประตูหน้าขวา */
.parts[data-id="tire-bt-28"] {
  top: 14rem;
  left: 26.3rem;
}

/* ประตูหน้าซ้าย */
.parts[data-id="tire-bt-29"] {
  top: 2rem;
  left: 25.3rem;
}

/* ประตูหลังขวา */
.parts[data-id="tire-bt-30"] {
  top: 14rem;
  left: 20rem;
}

/* ประตูหลังซ้าย */
.parts[data-id="tire-bt-31"] {
  top: 2rem;
  left: 18.8rem;
}

/* บังโคลนหลังขวา */
.parts[data-id="tire-bt-32"] {
  top: 14rem;
  left: 11.7rem;
}

/* บังโคลนหลังซ้าย */
.parts[data-id="tire-bt-33"] {
  top: 2rem;
  left: 12rem;
}

/* ฝาปืดท้าย */
.parts[data-id="tire-bt-34"] {
  top: 7rem;
  left: 6.7rem;
}

/* กันชนท้าย */
.parts[data-id="tire-bt-35"] {
  top: 8rem;
  left: 4rem;
}

/* หลังคา */
.parts[data-id="tire-bt-36"] {
  top: 7rem;
  left: 18rem;
}

/* ล้อหน้าขวา */
.parts[data-id="tire-bt-37"] {
  top: 14.8rem;
  left: 35.7rem;
}

/* ล้อหน้าซ้าย */
.parts[data-id="tire-bt-38"] {
  top: 0.7rem;
  left: 35.6rem;
}

/* ล้อหลังขวา */
.parts[data-id="tire-bt-39"] {
  top: 14.8rem;
  left: 14.6rem;
}

/* ล้อหลังซ้าย */
.parts[data-id="tire-bt-40"] {
  top: 0.7rem;
  left: 14.6rem;
}

/* กระจกบังลมหน้า */
.parts[data-id="tire-bt-41"] {
  top: 8rem;
  left: 30.9rem;
}
/* กระจกบังลมหลัง */
.parts[data-id="tire-bt-42"] {
  top: 7.4rem;
  left: 12.3rem;
}

.parts[data-id="tire-bt-12"],
.parts[data-id="tire-bt-13"],
.parts[data-id="tire-bt-14"] {
  display: none !important;
}

@media only screen and (max-width: 1021px) {
  /* กันชนหน้า */
  .parts[data-id="tire-bt-24"] {
    top: 6rem;
    left: 34rem;
  }

  /* ฝากระโปรงหน้า */
  .parts[data-id="tire-bt-25"] {
    top: 7rem;
    left: 28.5rem;
  }

  /* บังโคลนหน้าขวา */
  .parts[data-id="tire-bt-26"] {
    top: 14rem;
    left: 28rem;
  }

  /* บังโคลนหน้าซ้าย */
  .parts[data-id="tire-bt-27"] {
    top: 3rem;
    left: 27.7rem;
  }

  /* ประตูหน้าขวา */
  .parts[data-id="tire-bt-28"] {
    top: 14rem;
    left: 21.3rem;
  }

  /* ประตูหน้าซ้าย */
  .parts[data-id="tire-bt-29"] {
    top: 2rem;
    left: 20.3rem;
  }

  /* ประตูหลังขวา */
  .parts[data-id="tire-bt-30"] {
    top: 14rem;
    left: 16rem;
  }

  /* ประตูหลังซ้าย */
  .parts[data-id="tire-bt-31"] {
    top: 2rem;
    left: 15.8rem;
  }

  /* บังโคลนหลังขวา */
  .parts[data-id="tire-bt-32"] {
    top: 14rem;
    left: 9.7rem;
  }

  /* บังโคลนหลังซ้าย */
  .parts[data-id="tire-bt-33"] {
    top: 2rem;
    left: 9rem;
  }

  /* ฝาปืดท้าย */
  .parts[data-id="tire-bt-34"] {
    top: 7rem;
    left: 5.2rem;
  }

  /* กันชนท้าย */
  .parts[data-id="tire-bt-35"] {
    top: 8rem;
    left: 3rem;
  }

  /* หลังคา */
  .parts[data-id="tire-bt-36"] {
    top: 8rem;
    left: 14rem;
  }

  /* ล้อหน้าขวา */
  .parts[data-id="tire-bt-37"] {
    top: 15rem;
    left: 26.1rem;
  }

  /* ล้อหน้าซ้าย */
  .parts[data-id="tire-bt-38"] {
    top: 0.7rem;
    left: 26.6rem;
  }

  /* ล้อหลังขวา */
  .parts[data-id="tire-bt-39"] {
    top: 15.3rem;
    left: 11.6rem;
  }

  /* ล้อหลังซ้าย */
  .parts[data-id="tire-bt-40"] {
    top: 0.7rem;
    left: 11.6rem;
  }

  /* กระจกบังลมหน้า */
  .parts[data-id="tire-bt-41"] {
    top: 8rem;
    left: 23.9rem;
  }
  /* กระจกบังลมหลัง */
  .parts[data-id="tire-bt-42"] {
    top: 7.4rem;
    left: 10.3rem;
  }

  .content .img-fluid {
    width: 600px;
    height: 300px;
  }

  .content .mx-auto {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 821px) {
  /* กันชนหน้า */
  .parts[data-id="tire-bt-24"] {
    top: 5rem;
    left: 23rem;
  }

  /* ฝากระโปรงหน้า */
  .parts[data-id="tire-bt-25"] {
    top: 5rem;
    left: 19.5rem;
  }

  /* บังโคลนหน้าขวา */
  .parts[data-id="tire-bt-26"] {
    top: 9rem;
    left: 17rem;
  }

  /* บังโคลนหน้าซ้าย */
  .parts[data-id="tire-bt-27"] {
    top: 1.5rem;
    left: 16.7rem;
  }

  /* ประตูหน้าขวา */
  .parts[data-id="tire-bt-28"] {
    top: 9.5rem;
    left: 14.3rem;
  }

  /* ประตูหน้าซ้าย */
  .parts[data-id="tire-bt-29"] {
    top: 1.5rem;
    left: 14.3rem;
  }

  /* ประตูหลังขวา */
  .parts[data-id="tire-bt-30"] {
    top: 9.5rem;
    left: 11rem;
  }

  /* ประตูหลังซ้าย */
  .parts[data-id="tire-bt-31"] {
    top: 1.5rem;
    left: 10.8rem;
  }

  /* บังโคลนหลังขวา */
  .parts[data-id="tire-bt-32"] {
    top: 9.2rem;
    left: 5.9rem;
  }

  /* บังโคลนหลังซ้าย */
  .parts[data-id="tire-bt-33"] {
    top: 1.5rem;
    left: 6rem;
  }

  /* ฝาปืดท้าย */
  .parts[data-id="tire-bt-34"] {
    top: 5rem;
    left: 3.7rem;
  }

  /* กันชนท้าย */
  .parts[data-id="tire-bt-35"] {
    top: 5rem;
    left: 1.5rem;
  }

  /* หลังคา */
  .parts[data-id="tire-bt-36"] {
    top: 5rem;
    left: 11rem;
  }

  /* ล้อหน้าขวา */
  .parts[data-id="tire-bt-37"] {
    top: 10.2rem;
    left: 18.7rem;
  }

  /* ล้อหน้าซ้าย */
  .parts[data-id="tire-bt-38"] {
    top: 0.7rem;
    left: 18.6rem;
  }

  /* ล้อหลังขวา */
  .parts[data-id="tire-bt-39"] {
    top: 9.8rem;
    left: 7.6rem;
  }

  /* ล้อหลังซ้าย */
  .parts[data-id="tire-bt-40"] {
    top: 0.7rem;
    left: 8.6rem;
  }

  /* กระจกบังลมหน้า */
  .parts[data-id="tire-bt-41"] {
    top: 5.4rem;
    left: 16.3rem;
  }
  /* กระจกบังลมหลัง */
  .parts[data-id="tire-bt-42"] {
    top: 5.4rem;
    left: 6.3rem;
  }

  .content .img-fluid {
    width: 400px;
    height: 200px;
  }

  .content .mx-auto {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 621px) {
  /* กันชนหน้า */
  .parts[data-id="tire-bt-24"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 15rem;
  }

  /* ฝากระโปรงหน้า */
  .parts[data-id="tire-bt-25"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 12.5rem;
  }

  /* บังโคลนหน้าขวา */
  .parts[data-id="tire-bt-26"] {
    height: 20px;
    width: 20px;
    top: 7rem;
    left: 10.6rem;
  }

  /* บังโคลนหน้าซ้าย */
  .parts[data-id="tire-bt-27"] {
    height: 20px;
    width: 20px;
    top: 1rem;
    left: 10.7rem;
  }

  /* ประตูหน้าขวา */
  .parts[data-id="tire-bt-28"] {
    height: 20px;
    width: 20px;
    top: 7rem;
    left: 9rem;
  }

  /* ประตูหน้าซ้าย */
  .parts[data-id="tire-bt-29"] {
    height: 20px;
    width: 20px;
    top: 1rem;
    left: 8.8rem;
  }

  /* ประตูหลังขวา */
  .parts[data-id="tire-bt-30"] {
    height: 20px;
    width: 20px;
    top: 7rem;
    left: 6.8rem;
  }

  /* ประตูหลังซ้าย */
  .parts[data-id="tire-bt-31"] {
    height: 20px;
    width: 20px;
    top: 1rem;
    left: 6.8rem;
  }

  /* บังโคลนหลังขวา */
  .parts[data-id="tire-bt-32"] {
    height: 20px;
    width: 20px;
    top: 7rem;
    left: 3.9rem;
  }

  /* บังโคลนหลังซ้าย */
  .parts[data-id="tire-bt-33"] {
    height: 20px;
    width: 20px;
    top: 1rem;
    left: 4rem;
  }

  /* ฝาปืดท้าย */
  .parts[data-id="tire-bt-34"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 2.5rem;
  }

  /* กันชนท้าย */
  .parts[data-id="tire-bt-35"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 1.1rem;
  }

  /* หลังคา */
  .parts[data-id="tire-bt-36"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 8rem;
  }

  /* ล้อหน้าขวา */
  .parts[data-id="tire-bt-37"] {
    height: 20px;
    width: 20px;
    top: 7.8rem;
    left: 11.7rem;
  }

  /* ล้อหน้าซ้าย */
  .parts[data-id="tire-bt-38"] {
    height: 20px;
    width: 20px;
    top: 0.2rem;
    left: 11.8rem;
  }

  /* ล้อหลังขวา */
  .parts[data-id="tire-bt-39"] {
    height: 20px;
    width: 20px;
    top: 7.8rem;
    left: 5rem;
  }

  /* ล้อหลังซ้าย */
  .parts[data-id="tire-bt-40"] {
    height: 20px;
    width: 20px;
    top: 0.1rem;
    left: 5.2rem;
  }

  /* กระจกบังลมหน้า */
  .parts[data-id="tire-bt-41"] {
    height: 20px;
    width: 20px;
    top: 4rem;
    left: 10.4rem;
  }
  /* กระจกบังลมหลัง */
  .parts[data-id="tire-bt-42"] {
    height: 20px;
    width: 20px;
    top: 4.1rem;
    left: 4.3rem;
  }

  .content .mx-auto {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .content .img-fluid {
    width: 250px;
    height: 150px;
  }
}

/* @media only screen and (max-width: 435px) {
  .content .tire-bt-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 5.4rem;
    left: 2.9rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .tire-bt-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 5.5rem;
    left: 6.3rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .tire-top-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0rem;
    left: 3.3rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .tire-top-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0rem;
    left: 6.6rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .hood-front {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.7rem;
    left: 6.8rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .hood-back {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 3rem;
    left: 1.7rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mirror-front {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.7rem;
    left: 5.7rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mirror-back {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.7rem;
    left: 2.6rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .door-bt-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 4.7rem;
    left: 5rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .door-top-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0.7rem;
    left: 5.1rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .door-top-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0.7rem;
    left: 4rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .door-bt-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 4.7rem;
    left: 4rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .loof {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.7rem;
    left: 4rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .bumper-front {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.6rem;
    left: 8.2rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .bumper-back {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 2.5rem;
    left: 1rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mudguard-front-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0.8rem;
    left: 6.2rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mudguard-front-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 4.6rem;
    left: 6rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mudguard-back-left {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 0.6rem;
    left: 2.7rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .mudguard-back-right {
    height: 12px;
    width: 12px;
    background-color: #d00217;
    position: absolute;
    top: 4.6rem;
    left: 2.7rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
  }

  .content .img-fluid {
    width: 130px;
    height: 100px;
  }

  .content .mx-auto {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
} */

.content {
  position: relative;
}

.img-center {
  display: block;
  margin: 0 auto;
}

.alert-front {
  z-index: 9999;
}

.mx-auto {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.bg-dark-gray {
  background-color: #808080;
}
</style>

<template>
  <div>
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      :spinner-variant="this.$overlayVariant"
      :spinner-type="this.$overlayType"
      :rounded="this.$overlayRounded"
    >
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
          <b-button
            variant="danger"
            class="m-2 float-end"
            @click="print(inspId)"
            ><i class="uil uil-print"></i
          ></b-button>
        </div>
      </div>
      <b-table-simple caption-top responsive>
        <b-thead
          v-for="(inspGroup, indexGroup) in inspectionDetailData"
          :key="indexGroup"
        >
          <b-tr>
            <b-th variant="light" width="300">{{ inspGroup.nameTh }}</b-th>
            <b-th variant="light" width="300">
              {{ inspGroup.practiceTh }}
            </b-th>
            <b-th variant="light" v-if="inspGroup.choiceTextTh != null">
              {{ inspGroup.choiceTextTh }}</b-th
            >
            <b-th
              variant="light"
              width="100"
              v-for="(inspChoice, indexChoice) in inspGroup.choice"
              :key="indexChoice"
            >
              <span v-if="inspGroup.choiceTextTh === null">{{
                inspChoice.shortText
              }}</span>
            </b-th>

            <b-th colspan="2" variant="light"></b-th>
          </b-tr>
          <b-tr>
            <b-td colspan="6">
              <div
                class="content img-center"
                id="img_content"
                v-if="inspGroup.groupId === 3"
              >
                <img
                  src="@/assets/images/mycars2.png"
                  class="d-block img-fluid mx-auto"
                  width="800"
                  height="300"
                  style="position: relative"
                  fluid
                />
                <div
                  v-for="(inspimgRule, indeximgRule) in inspGroup.rule"
                  :key="'img' + indeximgRule"
                  class="parts"
                  v-bind:class="{ 'bg-dark-gray': inspimgRule.numFiles === 0 }"
                  :data-id="`tire-bt-${inspimgRule.ruleId}`"
                  @click="test(inspimgRule.ruleId, inspimgRule.nameTh, inspId)"
                  @click.prevent="$bvModal.show(`modalparts-${inspId}`)"
                ></div>
              </div>
            </b-td>
          </b-tr>

          <b-tr
            v-for="(inspRule, indexRule) in inspGroup.rule"
            :key="indexRule"
          >
            <b-td v-if="inspRule.ruleType != 'IMG'">{{ inspRule.nameTh }}</b-td>
            <b-td v-if="inspRule.ruleType != 'IMG'"
              >{{ inspRule.practiceTh }}
              <input
                v-if="inspRule.hasTextField === 1 && inspRule.ruleType != 'IMG'"
                v-model="inspRule.extraValue"
                type="text"
                class="form-control"
                :id="'extra' + inspId + 'extra' + inspRule.ruleId"
                @keyup.enter="
                  updateExtraValue(inspId, inspRule.ruleId, inspRule.extraValue)
                "
              />
              <!-- <vue-slide-bar
              v-if="inspRule.isFuel === 1"
              v-model="inspRule.extraValue"
              :id="'fuel' + inspId + 'fuels' + inspRule.ruleId"
              :name="'fuel' + inspId + 'fuels' + inspRule.ruleId"
              :data="sliderWithLabel.data"
              :range="sliderWithLabel.range"
              @callbackRange="callbackRange()"
              @change="
                updateExtraFuel(inspId, inspRule.ruleId, inspRule.extraValue)
              "
            /> -->

              <span
                class="badge bg-success float-end m-2 font-size-14"
                v-if="inspRule.isFuel === 1"
                >ระดับน้ำมัน <span v-if="inspRule.extraValue === null">0</span>
                {{ inspRule.extraValue }}</span
              >
              <div
                class="btn-group me-2"
                role="group"
                aria-label="First group"
                v-if="inspRule.isFuel === 1"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '1')"
                >
                  1
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '2')"
                >
                  2
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '3')"
                >
                  3
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '4')"
                >
                  4
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '5')"
                >
                  5
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '6')"
                >
                  6
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '7')"
                >
                  7
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '8')"
                >
                  8
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '9')"
                >
                  9
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateExtraFuel(inspId, inspRule.ruleId, '10')"
                >
                  10
                </button>
              </div>
            </b-td>
            <b-td
              colspan="3"
              v-if="
                inspRule.ruleType != 'IMG' &&
                  inspRule.ruleId != 12 &&
                  inspRule.ruleId != 13 &&
                  inspRule.ruleId != 23
              "
            >
              <b-form-radio-group
                v-model="
                  inspectionDetailData[indexGroup].rule[indexRule].selectChoice
                "
                :name="'inspId' + inspId + 'ruleId' + inspRule.ruleId"
              >
                <td width="150">
                  <b-form-radio
                    value="0"
                    @change="updateSelectChoice(inspId, inspRule.ruleId, 0)"
                  ></b-form-radio>
                </td>
                <td width="150">
                  <b-form-radio
                    value="1"
                    @change="updateSelectChoice(inspId, inspRule.ruleId, 1)"
                  ></b-form-radio>
                </td>
                <td width="150">
                  <b-form-radio
                    value="2"
                    @change="updateSelectChoice(inspId, inspRule.ruleId, 2)"
                  ></b-form-radio>
                </td> </b-form-radio-group
            ></b-td>
            <b-td
              colspan="3"
              v-if="inspRule.seq === 1 && inspRule.groupId === 3"
            >
              <b-form-textarea
                :id="'insp' + inspId + 'rule' + inspRule.ruleId"
                v-model="inspRule.note"
                rows="3"
                max-rows="6"
                @keyup.enter="
                  updateNote(inspId, inspRule.ruleId, inspRule.note)
                "
              ></b-form-textarea>
            </b-td>
            <b-td align="center" colspan="3" v-if="inspRule.ruleId === 13">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="inspRule.selectChoice"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                  @change="
                    updateSelected(
                      inspId,
                      inspRule.ruleId,
                      inspRule.selectChoice
                    )
                  "
                ></b-form-radio-group>
              </b-form-group>
            </b-td>
            <b-td align="center" colspan="3" v-if="inspRule.ruleId === 23">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="inspRule.selectChoice"
                  :options="optionsClean"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
                  @change="
                    updateSelected(
                      inspId,
                      inspRule.ruleId,
                      inspRule.selectChoice
                    )
                  "
                ></b-form-radio-group>
              </b-form-group>
            </b-td>
            <b-td v-if="inspRule.ruleType != 'IMG'" colspan="2">
              <b-button
                variant="primary"
                :class="{ 'btn btn-danger': inspRule.numFiles === 0 }"
                v-b-modal.modalViewimage
                @click="getDataImage(inspId, inspRule.ruleId)"
                @click.prevent="$bvModal.show(`modal-${inspId}`)"
                ><i class="uil uil-eye"></i
              ></b-button>
            </b-td>
          </b-tr>
        </b-thead>
      </b-table-simple>
    </b-overlay>

    <b-modal
      ref="modalViewimage"
      :id="'modal-' + inspId"
      title="รูปภาพประกอบ"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-12">
            <div class="mb-3 position-relative">
              <vue-dropzone
                id="imgFile"
                ref="imgFile"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="getDataImageUpseccess"
                v-on:vdropzone-sending="sendingEvent"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>อัพโหลดไฟล์ภาพ</h4>
                </div>
              </vue-dropzone>
              <b-overlay
                :show="overlayFlag"
                :spinner-variant="this.$overlayVariant"
                :spinner-type="this.$overlayType"
                :rounded="this.$overlayRounded"
              >
                <div class="card mt-4">
                  <div class="card-body shadow">
                    <h4 class="card-title">
                      อัลบั้มภาพ
                      <span class="badge bg-danger">{{
                        this.allImg.length
                      }}</span>
                    </h4>
                    <hr />
                    <div class="popup-gallery">
                      <div
                        v-for="(data, index) in rowsImg"
                        :key="'imgShow' + index"
                        class="float-start m-2"
                        @click="() => showImg(index)"
                      >
                        <div class="card shadow">
                          <img
                            :src="data.path"
                            class="d-block"
                            width="180"
                            height="180"
                          />
                        </div>
                      </div>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="this.allImg"
                        :index="index"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalViewParts"
      :id="`modalparts-${inspId}`"
      :title="`${this.titleParts}`"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-12">
            <div class="mb-3 position-relative">
              <vue-dropzone
                id="imgFile"
                ref="imgFile"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="getDataImageUpseccess"
                v-on:vdropzone-sending="sendingEvent"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>อัพโหลดไฟล์ภาพ</h4>
                </div>
              </vue-dropzone>
              <b-col sm="2" class="mt-4">
                <label for="textarea-small"><b>หมายเหตุ: </b></label>
              </b-col>
              <div
                v-for="(insptextGroup, indextextGroup) in inspectionDetailData"
                :key="'textarea' + indextextGroup"
              >
                <div
                  v-for="(insptextRule, indextextRule) in insptextGroup.rule"
                  :key="'textarea' + indextextRule"
                >
                  <div v-if="insptextRule.ruleId === imgruleId">
                    <!-- v-if="insptextRule.ruleId === 24" -->
                    <b-form-textarea
                      :id="'insptextRule' + insptextRule.ruleId"
                      v-model="insptextRule.note"
                      placeholder="ข้อความที่ต้องการระบุ"
                      :data-id="`${insptextRule.ruleId}`"
                      rows="4"
                      no-resize
                    ></b-form-textarea>

                    <b-button
                      class="btn btn-success mt-4"
                      @click="sendNote(insptextRule.note)"
                      >บันทึก</b-button
                    >
                  </div>
                </div>
              </div>
              <b-overlay
                :show="overlayFlag"
                :spinner-variant="this.$overlayVariant"
                :spinner-type="this.$overlayType"
                :rounded="this.$overlayRounded"
              >
                <div class="card mt-4">
                  <div class="card-body shadow">
                    <h4 class="card-title">
                      <span class="title-parts">อัลบั้มภาพ</span>
                      <span class="badge bg-danger">{{
                        this.allImg.length
                      }}</span>
                    </h4>
                    <hr />
                    <div class="popup-gallery">
                      <div
                        v-for="(data, index) in rowsImg"
                        :key="'imgShow' + index"
                        class="float-start m-2"
                        @click="() => showImg(index)"
                      >
                        <div class="card shadow">
                          <img
                            :src="data.path"
                            class="d-block"
                            width="180"
                            height="180"
                          />
                        </div>
                      </div>
                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="this.allImg"
                        :index="index"
                        @hide="handleHide"
                      ></vue-easy-lightbox>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
