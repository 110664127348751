<script>
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "tabDeliryDetail",
  props: {
    svdlvId: {
      required: true,
    },
  },
  components: {
    // vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      note: "",
      deliveryDetailData: [],
      imgruleId: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      svId: this.$route.params.svId,
      svIdDecode: this.$route.params.svId,
      resalert: "",
      token: "",
      overlayFlag: false,
      inspectionDetailData: [],
      inspection: [],
      extraValue: "",
      rowsImg: [],
      index: 0,
      visible: false,
      allImg: "",
      titleParts: "",

      dropzoneOptions: {
        url: `${appConfig.api.baseURL}api/service/inspection/image/upload`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        uploadMultiple: true,
        acceptedFiles: ".jpg, .jpeg, .png",
        // dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
        //   <p class="form-text">Allowed Files: .jpg, .jpeg, .png</p>
        //   `,
        paramName: "imgFile",
        init: function () {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // console.log(formData);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
    };
  },
  created() {
    this.getDeliveShowDetail();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },

    getDeliveShow: function () {
      const decodedString = atob(this.svIdDecode);
      this.svIdDecode = decodedString;
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery/show", {
          params: {
            svId: this.svIdDecode,
            svdlvId: this.svdlvId,
          },
        })
        .then((response) => {
          this.deliveryDetailData = response.data.saServiceDeliveryDetailData;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDeliveShowDetail: function () {
      const decodedString = atob(this.svIdDecode);
      this.svIdDecode = decodedString;
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery/show", {
          params: {
            svId: this.svIdDecode,
            svdlvId: this.svdlvId,
          },
        })
        .then((response) => {
          this.deliveryDetailData = response.data.saServiceDeliveryDetailData;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateSelectChoice(svdlvId, ruleId, selectChoice) {
      useNetw
        .put("api/service/service-delivery/update-select-choice", {
          svId: this.svIdDecode,
          svdlvId: svdlvId,
          ruleId: ruleId,
          selectChoice: JSON.stringify(selectChoice),
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateExtraValue(svdlvId, ruleId, extravalue) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: this.svIdDecode,
          svdlvId: svdlvId,
          ruleId: ruleId,
          extraValue: extravalue,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateNote(svdlvId, ruleId, note) {
      useNetw
        .put("api/service/service-delivery/update-choice-note", {
          svId: this.svIdDecode,
          svdlvId: svdlvId,
          ruleId: ruleId,
          note: note,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    updateSelected(svdlvId, ruleId, selectChoice) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: this.svIdDecode,
          inspId: svdlvId,
          ruleId: ruleId,
          selectChoice: selectChoice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    updateExtraFuel(svdlvId, ruleId, fuel) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: this.svIdDecode,
          inspId: svdlvId,
          ruleId: ruleId,
          extraValue: fuel,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getInspectionUpimgSuc();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataImage: function (svdlvId, ruleId) {
      this.overlayFlag = true;
      this.svdlvId = svdlvId;
      this.ruleId = ruleId;
      useNetw
        .get("api/service/inspection/images", {
          params: {
            svId: this.svIdDecode,
            svdlvId: svdlvId,
            ruleId: ruleId,
          },
        })
        .then((response) => {
          this.rowsImg = response.data.images;
          // console.log(response.data.images);

          const arrImg = [];
          this.rowsImg.forEach((item) => {
            arrImg.push(item.path);
          });
          this.allImg = arrImg;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataImageUpseccess: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection/images", {
          params: {
            svId: this.svIdDecode,
            svdlvId: this.svdlvId,
            ruleId: this.ruleId,
          },
        })
        .then((response) => {
          this.rowsImg = response.data.images;

          const arrImg = [];
          this.rowsImg.forEach((item) => {
            arrImg.push(item.path);
          });
          this.allImg = arrImg;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.getInspectionUpimgSuc();
        });
    },
    sendingEvent(file, xhr, formData) {
      formData.append("svId", this.svIdDecode);
      formData.append("inspId", this.inspId);
      formData.append("ruleId", this.ruleId);
    },
    print: function (svdlvId) {
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery/print", {
          params: {
            svId:
              this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
            svdlvId: svdlvId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
          // console.log(this.rowsTest.total);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
    test(ruleId, message, inspId) {
      this.imgruleId = ruleId;
      this.titleParts = message;
      this.inspId = inspId;
      this.getDataImage(inspId, ruleId);

      // this.showAlert(ruleId + message);
      // alert(message);
      // document.getElementById("tire-top-right").click();
    },
    sendNote(textnote) {
      useNetw
        .put("/api/service/inspection/update-choice-note", {
          svId: this.svIdDecode,
          inspId: this.inspId,
          ruleId: this.ruleId,
          note: textnote,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
  middleware: "authentication",
};
</script>

<style>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
.input-placeholder {
  font-size: 12px;
}
</style>

<template>
  <div>
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      :spinner-variant="this.$overlayVariant"
      :spinner-type="this.$overlayType"
      :rounded="this.$overlayRounded"
    >
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12">
          <b-button
            variant="danger"
            class="m-2 float-end"
            @click="print(svdlvId)"
            ><i class="uil uil-print"></i
          ></b-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead style="background-color: #c1c1c1">
            <tr>
              <th class="align-middle text-center" width="300">บริเวณ</th>
              <th class="align-middle text-center" width="300">ภาพอ้างอิง</th>
              <th class="align-middle text-center" width="300">จุดควบคุม</th>
              <th class="align-middle text-center" width="300">หมายเหตุ</th>
              <!-- <b-th colspan="2" variant="light"></b-th> -->
            </tr>
          </thead>
          <tbody
            v-for="(svdlvGroup, indexGroup) in deliveryDetailData"
            :key="'ONE' + indexGroup"
          >
            <tr>
              <th
                class="align-middle text-center"
                v-if="svdlvGroup.groupId === 1"
                width="300"
                rowspan="7"
              >
                {{ svdlvGroup.nameTh }}
              </th>
            </tr>
            <tr
              v-for="(svdlvRule, indexRule) in svdlvGroup.rule"
              :key="'GONE' + indexRule"
            >
              <td v-if="svdlvGroup.groupId === 1">
                {{ svdlvRule.exampleImage }}
              </td>
              <td v-if="svdlvGroup.groupId === 1">
                <div class="row m-1">
                  {{ indexRule + 1 }}. {{ svdlvRule.nameTh }}
                </div>
                <div class="col-12 align-middle text-center">
                  <div class="custom-radio form-check form-check-inline">
                    <b-form-radio
                      v-model="
                        deliveryDetailData[indexGroup].rule[indexRule]
                          .selectChoice
                      "
                      @change="updateSelectChoice(svdlvId, svdlvRule.ruleId, 1)"
                      id="isWaiting"
                      class="mb-3"
                      value="1"
                      plain
                      >ผ่าน</b-form-radio
                    >
                  </div>
                  <div class="custom-radio form-check form-check-inline">
                    <b-form-radio
                      v-model="
                        deliveryDetailData[indexGroup].rule[indexRule]
                          .selectChoice
                      "
                      @change="updateSelectChoice(svdlvId, svdlvRule.ruleId, 0)"
                      id="isWaiting2"
                      class="mb-3"
                      value="0"
                      plain
                      >ไม่ผ่าน</b-form-radio
                    >
                  </div>
                </div>
              </td>
              <td v-if="svdlvGroup.groupId === 1">
                <div class="row m-1">
                  {{ svdlvRule.ruleDetail }}
                </div>
                <div class="col-12">
                  <div class="mb-3 position-relative">
                    <input
                      id="note"
                      v-model="svdlvRule.note"
                      type="text"
                      class="form-control input-placeholder"
                      placeholder="เพิ่มเติม"
                      @keyup.enter="
                        updateNote(svdlvId, svdlvRule.ruleId, svdlvRule.note)
                      "
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th
                class="align-middle text-center"
                v-if="svdlvGroup.groupId === 2"
                width="300"
                rowspan="7"
              >
                {{ svdlvGroup.nameTh }}
              </th>
            </tr>
            <tr
              v-for="(svdlvRule, indexRule) in svdlvGroup.rule"
              :key="'GTWO' + indexRule"
            >
              <td v-if="svdlvGroup.groupId === 2">
                {{ svdlvRule.exampleImage }}
              </td>
              <td v-if="svdlvGroup.groupId === 2">
                <div class="row m-1">
                  {{ indexRule + 6 }}. {{ svdlvRule.nameTh }}
                </div>
                <div class="col-12 align-middle text-center">
                  <div class="custom-radio form-check form-check-inline">
                    <b-form-radio
                      v-model="
                        deliveryDetailData[indexGroup].rule[indexRule]
                          .selectChoice
                      "
                      id="isWaiting"
                      @change="updateSelectChoice(svdlvId, svdlvRule.ruleId, 1)"
                      class="mb-3"
                      value="1"
                      plain
                      >ผ่าน</b-form-radio
                    >
                  </div>
                  <div class="custom-radio form-check form-check-inline">
                    <b-form-radio
                      v-model="
                        deliveryDetailData[indexGroup].rule[indexRule]
                          .selectChoice
                      "
                      @change="updateSelectChoice(svdlvId, svdlvRule.ruleId, 0)"
                      id="isWaiting2"
                      class="mb-3"
                      value="0"
                      plain
                      >ไม่ผ่าน</b-form-radio
                    >
                  </div>
                </div>
              </td>
              <td v-if="svdlvGroup.groupId === 2">
                <div class="row m-1">
                  {{ svdlvRule.ruleDetail }}
                </div>
                <div class="col-12">
                  <div class="mb-3 position-relative">
                    <input
                      id="note"
                      v-model="svdlvRule.note"
                      type="text"
                      class="form-control input-placeholder"
                      placeholder="เพิ่มเติม"
                      @keyup.enter="
                        updateNote(svdlvId, svdlvRule.ruleId, svdlvRule.note)
                      "
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </div>
</template>
