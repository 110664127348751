<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Tabcomponents from "@/components/tabRoComponent";
import TabServicecomponents from "@/components/tabServiceComponent";
// import tabAppraiseComponents from "@/components/tabAppraiseComponent";
// import tabCheckAfterrepairComponent from "@/components/tabCheckAfterrepairComponent";
// import tableData from "@/components/tablecomponent";
import tabAlbumComponent from "@/components/tabAlbumComponent";
import useNetw from "@useNetw";
import tabSvDeliveryComponent from "@/components/tabSvDeliveryComponent";
// import useNetw from "@useNetw";
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ใบตรวจสภาพรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Tabcomponents,
    TabServicecomponents,
    tabSvDeliveryComponent,
    // tabAppraiseComponents,
    // tabCheckAfterrepairComponent,
    // tableData,
    tabAlbumComponent,
  },
  data() {
    return {
      // tableData: tableData,
      svId: this.$route.params.id,
      svIdCre: this.$route.params.svId,

      title: "ใบตรวจสภาพรถ",
      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: "การเข้ารับบริการ",
          active: false,
          href: "/servicelist",
        },
        {
          text: "ใบตรวจสภาพรถ",
          active: true,
        },
      ],
      selectedDefault: 0,
      rowsSelect: [],
      chk: "",
      selected: [],
      perPage: 5,
      currentPage: 1,
      pageOptions: [5, 25, 50, 100],
      filter: null,
      filterOn: [],
      rowsTest: [],
      rowsCustomer: [],
      rows: [{ total: "" }],
      sortBy: "age",
      sortDesc: false,
      fields2: [
        {
          key: "title",
          label: "การเตรียมการ",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields3: [
        {
          key: "title",
          label: "ภายในรถ",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "vehicleId",
          label: "vehicleId",
          sortable: true,
        },
        {
          key: "vin",
          label: "vin",
          sortable: true,
        },
        {
          key: "licensePlate",
          label: "licensePlate",
          sortable: true,
        },
        {
          key: "vehicleBrandEn",
          label: "vehicleBrandEn",
          sortable: true,
        },
        {
          key: "vehicleModelEn",
          label: "vehicleModelEn",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rowsTest.length;
    // },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.rowsTest.length;
    this.getDataCustomer();
  },
  created() {
    this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData: function () {
      const decodedString = atob(this.svIdCre);
      this.svIdCre = decodedString;
      this.overlayFlag = true;
      useNetw
        .get("api/service/inspection", {
          params: {
            page: 1,
            perPage: 100,
            svId: this.svIdCre != null ? this.svIdCre : this.svId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataCustomer: function () {
      this.overlayFlag = true;
      if (this.svIdCre != null) {
        useNetw
          .get("api/service/show", {
            params: {
              svId: this.svIdCre,
            },
          })
          .then((response) => {
            this.rowsCustomer = response.data;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push("/login");
            }
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
      if (this.svId != null) {
        useNetw
          .get("api/service/show", {
            params: {
              svId: this.svId,
            },
          })
          .then((response) => {
            this.rowsCustomer = response.data;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push("/login");
            }
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsTest) {
      this.selected = rowsTest;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // selectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.selectRow(2)
    // },
    // unselectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.unselectRow(2)
    // }
  },
  middleware: "authentication",
};
</script>
<style>
.width-table-60 {
  max-width: 60px;
}
.width-table-50 {
  max-width: 50px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="12">
                <div class="row pt-4">
                  <div class="col-6 col-lg-6 col-md-6">
                    <div>
                      <span class="text-muted font-size-20 float-left"
                        ><b>Service</b></span
                      >
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6">
                    <div>
                      <span class="text-muted font-size-20 float-end"
                        ><b>ใบตรวจสภาพรถ</b></span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-lg-6 col-md-6 pb-2">
                    <div>
                      <span class="text-muted font-size-20 float-left"
                        >ศูนย์บริการ</span
                      >
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 pb-2">
                    <div>
                      <span class="text-muted font-size-20 float-end"
                        >Service Inspection</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div
                    class="card-body shadow"
                    v-for="(dataSv, index) in rowsCustomer"
                    :key="index"
                  >
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16">
                          <b>คุณ</b> {{ dataSv.nameTh }} {{ dataSv.familyNameTh }}
                        </span>
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>ป้ายทะเบียน :</b> {{ dataSv.licensePlate }}
                        </span>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16"
                          ><b>เลขตัวถัง :</b> {{ dataSv.vin }}</span
                        >
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>รับรถวันที่ :</b> {{ dataSv.svDate }}
                        </span>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16"
                          ><b>เบอร์โทรศัพท์ :</b> {{ dataSv.mobilePhone }}</span
                        >
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>วันที่เสร็จสิ้น :</b> {{ dataSv.expectFinishDate }}
                        </span>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16"
                        ><b>เลขที่เข้ารับบริการ :</b> {{ dataSv.svCode }}</span
                        >
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>เวลาเสร็จสิ้น :</b> {{ dataSv.expectFinishTime }}
                        </span>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16"
                        ><b>ประเภทเข้ารับบริการ :</b> {{ dataSv.distanceNameTh }}</span
                        >
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>ยี่ห้อ : </b>{{ dataSv.vehicleBrandEn }}
                        </span>
                      </div>
                    </div>
                    <div class="row m-2">
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16"
                        ><b>สาขาที่เข้ารับบริการ :</b> {{ dataSv.branchName }}</span
                        >
                      </div>
                      <div class="col-12 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          ><b>รุ่นรถ :</b> {{ dataSv.vehicleModelEn }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="row m-2">
                      <div class="col-6 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16">ประเภท : </span>
                      </div>
                      <div class="col-6 col-lg-6 col-md-6">
                        <span class="text-muted font-size-16 float-end"
                          >สาขา :
                        </span>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-0 shadow">
              <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                <tabAlbumComponent></tabAlbumComponent>

                <TabServicecomponents></TabServicecomponents>

                <!-- <tabAppraiseComponents></tabAppraiseComponents> -->

                <Tabcomponents :recievedSvId="this.svId"></Tabcomponents>
                <tabSvDeliveryComponent :recievedSvId="this.svId"></tabSvDeliveryComponent>

                <!-- <tabCheckAfterrepairComponent></tabCheckAfterrepairComponent> -->

                <!-- <b-tab>
                  <template v-slot:title>
                    <i class="uil uil-envelope-alt font-size-20"></i>
                    <span class="d-none d-sm-block">Messages</span>
                  </template>
                  <b-overlay
                    :show="overlayFlag"
                    :spinner-variant="this.$overlayVariant"
                    :spinner-type="this.$overlayType"
                    :rounded="this.$overlayRounded"
                  >
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                    <tableData :fields="fields" :items="rows">
                      <template #cell(action)="rows">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <router-link
                              href="javascript:void(0);"
                              class="px-2 text-primary"
                              v-b-tooltip.hover
                              :to="{
                                name: 'vehicleformdetail',
                                params: { vehicleId: rows.item.vehicleId },
                              }"
                              title="Edit"
                            >
                              <i class="uil uil-pen font-size-18"></i>
                            </router-link>
                          </li>
                          <li class="list-inline-item">
                            <a
                              href="javascript:void(0);"
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </template>
                    </tableData>
                    <div class="row">
                      <div class="col">
                        <div class="align-items-center">
                          แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                          {{ this.rows.total }}
                        </div>
                        <div
                          class="dataTables_paginate paging_simple_numbers float-end"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="rows.total"
                              :per-page="perPage"
                              @change="handleChangePage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </b-overlay>
                </b-tab> -->
              </b-tabs>
              <!-- Nav tabs -->
              <!-- Tab content -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
