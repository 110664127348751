<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import tabDeliryDetail from "./tabDeliryDetail.vue";
// import vue2Dropzone from "vue2-dropzone";

// import "vue2-dropzone/dist/vue2Dropzone.min.css";

// import tableData from "@/components/tablecomponent"

export default {
  name: "TabServicecomponents",
  page: {
    title: "Advanced Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    DatePicker,
    tabDeliryDetail,
    // vueDropzone: vue2Dropzone,
    // tableData,
  },
  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,

      inspections: [],

      rowsSelectOne0: "",
      rowsSelectOne1: "",
      rowsSelectOne2: 2,

      inspDate: "",
      distance: "",
      expectFinishTime: "",
      rowsInspid: "",
      svId: this.$route.params.id,
      svIdCre: this.$route.params.svId,
      svIdDecode: this.$route.params.id,
      svIdCreDecode: this.$route.params.svId,

      sliderWithLabel: {
        value: 1,
        data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        range: [
          {
            label: "1",
          },
          {
            label: "2",
            isHide: true,
          },
          {
            label: "3",
          },
          {
            label: "3",
            isHide: true,
          },
          {
            label: "5",
          },
          {
            label: "6",
            isHide: true,
          },
          {
            label: "7",
          },
          {
            label: "8",
            isHide: true,
          },
          {
            label: "9",
          },
          {
            label: "10",
            isHide: true,
          },
        ],
        rangeValue: {},
      },

      title: "ใบตรวจสภาพรถ",
      items: [
        {
          text: "Home",
        },
        {
          text: "ใบตรวจสภาพรถ",
          active: true,
        },
      ],
      svdlvId:"",
      rowSvDelivery:[],
      rows: [],
      options: [
        { text: " ครบ", value: "0" },
        { text: " ไม่ครบ", value: "1" },
      ],
      optionsClean: [
        { text: " ล้าง", value: "0" },
        { text: " ไม่ล้าง", value: "1" },
      ],
      inspType: "BP",
      index: 0,
      visible: false,
      distanceKg: "",
      inspId: "",
      extraValue: "",
      extraValue2: "",
      note: "",
      btnselected: "",
      btnselected2: "",

      rowsSelect: [],
      rowsMasterSD: [],
      rowsShow: [],
      rowsShowinspection: [],
      rowsImg: [],
      allImg: "",
      resalert: "",
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      filter: null,
      filterOn: [],
      rowsTest: [],
      token: "",
      sortBy: "age",
      sortDesc: false,
      submitform: false,
      allRuleid: "",
      formdis: {
        svId: "",
        svdlvType: "",
        svdlvDate: "",
      },
       optionRoType: [
        {
          id: "GS",
        },
        {
          id: "BP",
        },
        {
          id: "SLA",
        },
      ],
      fields2: [
        {
          nameTh: true,
          // sortable: false,
        },
        {
          key: "practiceTh",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields3: [
        {
          key: "title",
          label: "ภายในรถ",
          sortable: false,
        },
        {
          key: "selectRadio",
          label: "ปกติ",
          sortable: false,
          variant: "success",
          class: "width-table-50",
        },
        {
          key: "selectRadio1",
          label: "ตรวจเช็คครั้งถัดไป",
          sortable: false,
          variant: "warning",
          class: "width-table-50",
        },
        {
          key: "selectRadio2",
          label: "ต้องเข้าทันที",
          sortable: false,
          variant: "danger",
          class: "width-table-50",
        },
        {
          key: "camera",
          label: "",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "nameTh",
          label: "Id",
          sortable: false,
        },
        {
          key: "practiceTh",
          label: "Id",
          sortable: false,
        },
        {
          key: "radioBtn",
          label: "",
          sortable: false,
        },
        {
          key: "radioBtn2",
          label: "",
          sortable: false,
        },
        {
          key: "radioBtn3",
          label: "",
          sortable: false,
        },
      ],
      overlayFlag: false,
      dropzoneOptions: {
        url: `${appConfig.api.baseURL}api/service/inspection/image/upload`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        uploadMultiple: true,
        paramName: "imgFile",
        init: function () {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // console.log(formData);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
    };
  },
  validations: {
    inspDate: {
      required,
    },
    formdis: {
      svdlvType: {
        required,
      },
      svdlvDate: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.token = `${user[appConfig.storageTokenKeyName]}`;
    // this.token.push(JSON.stringify(this.user))
    // console.log('user',user.accessToken);
    // console.log('token',JSON.parse(localStorage.getItem(appConfig.storageUserKey)));
    // Set the initial number of items
    // this.totalRows = this.rowsTest.length;
  },
  created() {
    if (this.svIdCreDecode != null) {
      this.getData();
    }
    if (this.svIdDecode != null) {
      this.getDataDetail();
    }

    // if (){

    // }
    // this.getDataImage();
    // this.select()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    sendingEvent(file, xhr, formData) {
      formData.append("svId", this.svId);
      formData.append("inspId", this.inspId);
      formData.append("ruleId", this.allRuleid);
    },
    retrieveOptionsDiffPage(choiceId, ruleId) {
      ruleId.choiceId = choiceId;
    },
   
    getData: function () {
      const decodedString = atob(this.svIdCreDecode);

      this.svIdCreDecode = decodedString;
      // if (Number.isInteger(this.svIdCreDecode) === true) {
      //   const decodedStringsvId = atob(this.svIdDecode);
      //   this.svIdDecode = decodedStringsvId;
      // }
      // const decodedStringsvId = atob(this.svIdDecode);
      // this.svIdDecode = decodedStringsvId;
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
          },
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.rowSvDelivery = response.data.data;

            this.getDeliveShow();
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    loadData: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdCreDecode != null ? this.svIdCreDecode : this.svIdDecode,
          },
        })
        .then((response) => {
          this.rowSvDelivery = response.data.data;
          this.rowsDeli = response.data.data[0].inspId;
          // this.rows.total = response.data.total;

          this.getDeliveShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataDetail: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/service/service-delivery", {
          params: {
            page: 1,
            perPage: 100,
            svId:
              this.svIdCreDecode != null ? this.svIdCreDecode : this.svIdDecode,
          },
        })
        .then((response) => {
         this.row = response.data.data;
          this.rowsDeli = response.data.data[0].svdlvId;
          // this.rows.total = response.data.total;

          this.getDeliveShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  
    loadDataServiceDetail: function () {
      if (this.inspId != null) {
        this.overlayFlag = true;
        useNetw
          .get("api/service/service-delivery/show", {
            params: {
              svId:
                this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
              svdlvId: this.svdlvId,
              // svId: this.svId,
              // inspId: this.inspId,
            },
          })
          .then((response) => {
            this.rowsShow = response.data.inspectionDetailData;
            this.rowsShowinspection = response.data.inspection;
            // console.log(this.rowsTest.total);
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
   

    getDeliveShow: function () {
      if (this.rowsDeli != null) {
        this.overlayFlag = true;
        useNetw
          .get("api/service/service-delivery/show", {
            params: {
              svId:
                this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode,
              svdlvId: this.svdlvId,
            },
          })
          .then((response) => {
            this.rowSvDeliveryShow =
              response.data.data.saServiceDeliveryDetailData;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },

   
    formDis() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.formdis.$invalid != true) {
        this.postSvDelive();
      }
    },
     postSvDelive: function () {
      this.overlayFlag = true;
      const bodyFormData = new FormData();
      // // parse
      bodyFormData.append("svId", this.svIdDecode != null ? this.svIdDecode : this.svIdCreDecode);
      bodyFormData.append("svdlvType", this.formdis.svdlvType.id);
      bodyFormData.append("svdlvDate", this.formdis.svdlvDate);

      useNetw
        .post("api/service/service-delivery/store", bodyFormData)
        .then((response) => {
          this.svdlvId = response.data.svdlvId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.loadData();
          this.getDeliveShow();

          this.$refs.modalCreateDeli.hide();
          this.isHidden = true;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    uploadImgServiceInspection: function () {
      // this.svId.push({svId})
      // console.log(this.service)
      const bodyFormData = new FormData();
      bodyFormData.append("svId", 4);
      bodyFormData.append("inspId", 1);
      bodyFormData.append("ruleId", 1);
      // const imagefile = document.querySelector('#imgFile')
      // bodyFormData.append("imgFile", imagefile.file);
      useNetw
        .post("/api/service/inspection/image/upload", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            // console.log(this.type.typeId),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateService.hide();
          this.loadData();
          this.loadDataServiceDetail();
        });
    },
    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
    postAPI(svId, inspId, ruleId, value) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: JSON.stringify(value),
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          // console.log(svId, inspId, ruleId, value, "success");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postExtraValue(svId, inspId, ruleId, extraValue2) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: extraValue2,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          // console.log(svId, inspId, ruleId, extraValue2, "success");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postExtraFuel(svId, inspId, ruleId, fuel) {
      useNetw
        .put("/api/service/inspection/update-choice-value", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          extraValue: fuel,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          // console.log(svId, inspId, ruleId, fuel, "success");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postNote(svId, inspId, ruleId, note) {
      useNetw
        .put("/api/service/inspection/update-choice-note", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          note: note,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          // console.log(svId, inspId, ruleId, note, "success");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    postSelectChoice(svId, inspId, ruleId, selectChoice) {
      useNetw
        .put("/api/service/inspection/update-select-choice", {
          svId: svId,
          inspId: inspId,
          ruleId: ruleId,
          selectChoice: selectChoice,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          // console.log(svId, inspId, ruleId, selectChoice, "success");
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    chooseFiles(svId, inspId, ruleId) {
      document.getElementById("importFile").click();
      this.allRuleid = ruleId;
    },
    postImg(svId, inspId) {
      this.svId = svId;
      // console.log(svId, inspId, ruleId);
      const bodyFormData = new FormData();
      bodyFormData.append("svId", this.svId);
      bodyFormData.append("inspId", inspId);
      bodyFormData.append("ruleId", this.allRuleid);
      const imagefile = document.querySelector("#importFile");
      bodyFormData.append("imgFile[]", imagefile.files[0]);
      useNetw
        .post("/api/service/inspection/image/upload", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            // console.log(this.type.typeId),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCreateService.hide();
          this.loadData();
          this.loadDataServiceDetail();
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitServiceInspection();
      }
    },
  },
  middleware: "authentication",
};
</script>

<style>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

<template>
  <b-tab>
    <template v-slot:title>
      <i class="mdi mdi-car-arrow-right font-size-20"></i>
     
      <span class="d-none d-sm-block">ใบตรวจส่งมอบรถ</span>
    </template>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 text-end mb-2">
        <b-button variant="primary" v-b-modal.modalCreateDeli class="m-2"
          ><i class="uil uil-plus"></i>สร้าง</b-button
        >
      </div>
    </div>
    <div>
      <b-alert
        class="alert-fixed fixed-top rounded-1 m-4 bg-light"
        :show="dismissCountDown"
        dismissible
        fade
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p class="float-end">{{ dismissCountDown }} วินาที...</p>
        <p>แจ้งเตือน</p>
        <hr />
        <p>{{ resalert }}</p>
        <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
      </b-alert>
    </div>
    <div>
      <b-tabs justified nav-class="nav-tabs-custom" class="text-muted">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <b-tab
            :title="data.inspCode"
            v-for="(data, index) in rowSvDelivery"
            :key="index"
          >
            <tabDeliryDetail :svdlvId="data.svdlvId"></tabDeliryDetail>
          </b-tab>
        </b-overlay>
      </b-tabs>
    </div>

    <b-modal
      ref="modalCreateDeli"
      id="modalCreateDeli"
      title="สร้างใบตรวจส่งมอบ"
      hide-footer
      size="lg"
      centb-modalered
    >
      <div class="card-body">
        <form class="needs-validation" @submit.prevent="formDis">
          <div class="row">
            <div class="col-sm-4">
              <div class="mb-3 position-relative">
                <code>* </code><label for="svdlvDate">วันที่</label>
                <!-- disabled="disabled"  -->
                <date-picker
                  v-model="formdis.svdlvDate"
                  :first-day-of-week="1"
                  format="YYYY-MM-DD"
                  value-type="format"
                  lang="en"
                  :class="{
                    'is-invalid': submitform && $v.formdis.svdlvDate.$error,
                  }"
                ></date-picker>
                <div
                  v-if="submitform && $v.formdis.svdlvDate.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.formdis.svdlvDate.required">{{
                    message
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="mb-3 position-relative">
                <code>* </code><label for="svdlvType">ประเภท</label>
                <multiselect
                  id="svdlvType"
                  v-model="formdis.svdlvType"
                  :options="optionRoType"
                  :class="{
                    'is-invalid': submitform && $v.formdis.svdlvType.$error,
                  }"
                  open-direction="bottom"
                  track-by="id"
                  label="id"
                  :show-labels="false"
                >
                </multiselect>
                <div
                  v-if="submitform && $v.formdis.svdlvType.$error"
                  class="invalid-tooltip"
                >
                  <span v-if="!$v.formdis.svdlvType.required">{{
                    message
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row"></div>
    </b-modal>
  </b-tab>
</template>
